import React from 'react';
import './MisceleniousStyles.css'

const TaskType = (params: any) => {
    return (
        <div className='task-type-widget nonselectable' style={{borderColor: params.color}}>
            <div className='task-type-icon' dangerouslySetInnerHTML={{__html: params.icon}}/>
            <div className='task-type-text' style={{color: params.color}}>{params.name}</div>
        </div>

    );
}
export default TaskType;
