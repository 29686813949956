import React, { FC, useContext, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import Download from "../../../assets/download.svg"
import { API_URL } from '../../../http';
export interface IDownloadButtonProps {
    itemId: number
    onClickFunc: (itemId: number, type: string, name: string) => void;
    url: string,
    type: string,
    name: string
}

const BtnDownload: FC<IDownloadButtonProps> = ({ onClickFunc, itemId, url, type, name }) => {
    
    return (
        <div className="button-base">
            <a href={`${API_URL}/Project/getFile?fileId=${itemId}`} download>
                <img src={Download} />
            </a>
        </div>
    )
}

export default observer(BtnDownload);