import { createContext, FC, useContext, useEffect, useState } from "react";
import TaskInformationForm from "../../component/Task/TaskForm/TaskInformationForm";
import "./Task.css";
import TaskRightInfo from "../../component/Task/TaskRightInfo/TaskRightInfo";
import TaskChat from "../../component/Task/TaskChat/TaskChat";
import { AppContext } from "../../App";
import { Context } from "../..";
import { IBreadCrumb } from "../../models/IBreadCrumb";
import { Link, useParams } from "react-router-dom";
import { TreeNode } from "../../models/LeftMenuModels";
import { ITaskGeneralInfo } from "../../models/ITask";

interface TaskContextType {
    taskInformation: ITaskGeneralInfo | undefined;
    loadTaskInfo: () => void;
}

export const TaskContext = createContext<TaskContextType>({
    taskInformation: undefined,
    loadTaskInfo: () => {},
});

const Task: FC = (index) => {
    const { setBreadCrumb } = useContext(AppContext);
    const { store } = useContext(Context);
    const [defaultBoardId, setDefaultBoardId] = useState<number>();
    const { boardId, id } = useParams();

    const [taskInformation, setTaskInformation] = useState<ITaskGeneralInfo>();

    useEffect(() => {
        (async () => {
            await loadTaskInfo();
        })();
    }, []);

    useEffect(() => {
        let bread: IBreadCrumb[] = [
            {
                label: "Проекты",
                urll: "/projects",
            },
        ];
        const path = findNodePath(store.tree.nodes, `${defaultBoardId}-board`);
        if (path && taskInformation) {
            const pathForBread: IBreadCrumb[] = path.map((p) => {
                return {
                    label: p.label ?? "",
                    urll: p.key
                        ? "/" + (p.key as string).split("-").reverse().join("/")
                        : "",
                };
            });

            bread = [
                ...bread,
                ...pathForBread,
                {
                    label: taskInformation.shortDescription,
                    urll: `/task/${taskInformation.id}`,
                },
            ];

            bread.forEach(
                (b: any) =>
                    (b.template = () => (
                        <Link to={b.urll}>
                            <a>{b.label}</a>
                        </Link>
                    ))
            );
        }
        setBreadCrumb(bread);
    }, [setBreadCrumb, store.tree.nodes, taskInformation, defaultBoardId]);

    const findNodePath = (
        nodes: TreeNode[],
        targetLabel: string
    ): TreeNode[] | null => {
        for (const node of nodes) {
            if (node.key === targetLabel) return [node];
            if (node.children) {
                const path = findNodePath(node.children, targetLabel);
                if (path) return [node, ...path];
            }
        }
        return null;
    }

    const loadTaskInfo = async () => {
        let res = await store.getTask({
            taskId: Number(id),
            boardId: boardId ? Number(boardId) : undefined,
        });
        setTaskInformation(res);
        setDefaultBoardId(res?.board.id);
    };

    return (
        <TaskContext.Provider value={{ taskInformation, loadTaskInfo }}>
            <div className="widgets_task_container">
                <div className="widgets_task_container_1">
                    <div className="widgets_task_container_sub">
                        <TaskInformationForm projectId={taskInformation?.projectId}/>
                    </div>
                    <div>
                        <TaskChat />
                    </div>
                </div>
                <div className="widgets_task_container_2">
                    <TaskRightInfo />
                </div>
            </div>
        </TaskContext.Provider>
    );
};

export default Task;
