import React, { useContext, useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";
import "../../styles/team.pc.css";
import "../../styles/table.pc.css";
import "../Team/Team.css"
import Search from "../../Shared/Search/Search"
import BtnAdd from '../../buttons/btnAdd.component/BtnAdd';
import '../../../globalstyles/dataTableStyles.css'
import InputSaveOnEdit from "../InputSaveOnWidjet/InputSaveOnWidjet"
import { Context } from "../../../index";
import { useParams } from "react-router-dom";
import PersonLineDisplay from "../../Shared/PersonLineDisplay";
import BtnCancel from "../../buttons/CancelButton/CancelButton";
import {
    IAddTeamDisplay,
    IDeleteTeamDisplay,
    IIPersonAddCommand,
    ITeamDisplay,
    ITeamMemberDisplay
} from "../../../models/TeamModels";
import BtnDelete from '../../buttons/btnDelete.component/BtnDelete';
import Checkbox from '../../Shared/Checkbox/Checkbox';
import { ProjectContext } from '../../../pages/Project/Project';
import FilterButton from '../../buttons/btnFilter.component/BtnFilter'
import ConfirmationWindow from '../../Shared/ConfirmationWindow/ConfirmationWindow';
import { initials } from '../../../helpers/Inicials';

const PAGE_SIZE = 5
interface Item {
    name: string;
    code: string;
}
const fakeFilters = {
    position: [
        {
            id: 1,
            name: "Архитектор"
        },
        {
            id: 2,
            name: "Разработчик"
        },
        {
            id: 3,
            name: "Аналитик"
        },
        {
            id: 4,
            name: "Ведущий аналитик"
        },
        {
            id: 5,
            name: "РП"
        }
    ]
}

export interface ITeamFilter {
    projectId: number,
    role: number[] | undefined,
    name: string | undefined
}

interface ITeamProps {
    members: any[];
    scrollEnded: boolean;
    loadPage: (skip: number, take: number, filters: ITeamFilter) => void;
    updateFilters: (filters: ITeamFilter) => void;
    loadTeamMembers: (skip: number, take: number, filters: ITeamFilter) => void;
}

const Team: React.FC<ITeamProps> = ({ members, scrollEnded, loadPage, updateFilters, loadTeamMembers }) => {
    const { persons, roles, filterDataTeam, checkedFiltersTeams,
        setCheckedFiltersTeams, applayFiltersTeams,
        resetFilter, setFilterDataTeam,
        selectedUsersTeams, setSelectedUsersTeams,
        counterTeam, setCounterTeam,
        pageTeam, setPageTeam, setScrollEndedTeam } = useContext(ProjectContext)
    const { store } = useContext(Context);
    const { id } = useParams()
    const [value, setValue] = useState();
    const [openInput, setOpenInput] = useState(false);
    const [selectedUser, setSelectedUser] = useState<Item | null>(null);
    const [selectedRole, setSelectedRole] = useState<Item | null>(null);
    const [deledMemberIds, setDeledMemberIds] = useState<{personId: number, roleId: number}[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [openConfirmWindow, setOpenConfirmWindow] = useState(false);
    const [errMess, setErrMess] = useState('');
    const curRef = useRef(null);
    const nullFilter: ITeamFilter = {
        projectId: Number(id),
        role: undefined,
        name: undefined,
    }

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (event.key === 'Escape') {
                event.preventDefault();
                setOpenInput(false);
                onCancelClick();
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        // Clean up event listener
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    const onScroll = (e: any) => {
        checkIfScrolledToBottom(e)
    };

    function checkIfScrolledToBottom(e: any) {
        const { scrollTop, offsetHeight, scrollHeight } = e.target;
        if ((1 + scrollTop + offsetHeight >= scrollHeight) && (!scrollEnded)) {
            setPageTeam((prev: number) => prev + 1);
            (async () => {
                await loadPage(pageTeam * PAGE_SIZE, PAGE_SIZE, checkedFiltersTeams);
            })();
        }
    }

    const personRowDisplay = (rowData: any) => {
        return <PersonLineDisplay name={initials(rowData.responsibleName)} />
    };

    function onSearchSubmit(value: string) {
        (curRef.current as any).scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        let newFilters = { ...checkedFiltersTeams };
        newFilters.name = value;
        setCheckedFiltersTeams(newFilters);
        (async () => {
            await updateFilters(newFilters);
        })();

    }

    function onAddClick() {
        setOpenInput(!openInput)
    }

    function onCancelClick() {
        (curRef.current as any).scrollTo({
            top: 0,
            left: 0,
        });
        resetFilter('team');
        setSearchValue('');
        setPageTeam(1);
    }


    const saveUserOnTeam = (e: any) => {
        e.preventDefault();
        if (selectedUser && selectedRole) {
            let team: IIPersonAddCommand = {
                personId: Number(selectedUser.code),
                roleId: Number(selectedRole.code)
            };
            let body: IAddTeamDisplay = {
                projectId: Number(id),
                team: [team]
            };

            (async () => {
                await store.addTeamMembers(body);
                setSelectedUser(null);
                setSelectedRole(null);
                setOpenInput(false);
                await loadTeamMembers(0, PAGE_SIZE, {
                    projectId: Number(id),
                    name: undefined,
                    role: undefined
                })
            })();
            setScrollEndedTeam(false);
            (curRef.current as any).scrollTo({
                top: 0,
                left: 0,
            });
            setPageTeam(1);
        }
    }

    const handleTeamSelection = (memberId: number, event: any, nameForFilter?: string , roleId?: number) => {
        let ids = deledMemberIds;

        if (event.target.checked && roleId) {
            ids.push({personId: memberId, roleId: roleId});
        } else {
            // let index = ids.findIndex(i => i === memberId);
            // if (index !== -1) {
            //     ids.splice(index, 1);
            // }
            ids = ids.filter(xx=>xx.personId != memberId)
        }
        setDeledMemberIds([...ids]);
    };

    function onDelete() {
        //Тут нужен маппинг с ролью для удаления
        let body: IDeleteTeamDisplay = {
            projectId: Number(id),
            team: deledMemberIds.map(item=> ({
                personId: item.personId,
                roleId: item.roleId
            }))
        }

        deledMemberIds?.length && (async () => {
            let res = await store.deleteTeamMembers(body);
            if (res !== null) {

                setErrMess(res);
                alert(res);

            } else {
                loadTeamMembers(0, PAGE_SIZE, {
                    projectId: Number(id),
                    name: undefined,
                    role: undefined
                })

                setDeledMemberIds([]);
                setScrollEndedTeam(false);
                (curRef.current as any).scrollTo({
                    top: 0,
                    left: 0,
                });
                setPageTeam(1);
            }

        })();
    }

    const onConfirm = () => {
        onDelete();
        setOpenConfirmWindow(false);
    }
    const onCancel = () => {
        setOpenConfirmWindow(false);
    }

    const openConfWindow = () => {
        setOpenConfirmWindow(true);
    }

    return (
        <div id='main-team-widget' className='widget_wrapper'>
            {openConfirmWindow && (deledMemberIds?.length === 1 ? <ConfirmationWindow headerText='Удалить сотрудника из команды?'
                bodyText='Вы уверены, что хотите удалить сотрудника?'
                confirmText='Да' cancelText='Нет' onConfirm={onConfirm} onCancel={onCancel} /> : <ConfirmationWindow headerText='Удалить сотрудников из команды?'
                    bodyText='Вы уверены, что хотите удалить сотрудников?'
                    confirmText='Да' cancelText='Нет' onConfirm={onConfirm} onCancel={onCancel} />)}
            <div className='widgets_header'>
                <div>
                    <h2 className='h2_20-black'>Команда</h2>
                </div>
                <div className='widjets_header_rigth'>
                    <Search onSubmitFunc={onSearchSubmit} defaultValue={searchValue} changeSearchValue={setSearchValue} />
                    <FilterButton filterData={filterDataTeam}
                        setFilterData={setFilterDataTeam}
                        applayFilters={applayFiltersTeams}
                        checkedFilters={checkedFiltersTeams}
                        setCheckedFilters={setCheckedFiltersTeams}
                        selectedUsers={selectedUsersTeams} setSelectedUsers={setSelectedUsersTeams}
                        counter={counterTeam} setCounter={setCounterTeam}
                        showApplyButton={true}
                    />
                    {(counterTeam !== 0 || searchValue) && <BtnCancel onClickFunc={onCancelClick} />}
                    {deledMemberIds?.length !== 0 && <BtnDelete onClickFunc={openConfWindow} />}
                    <BtnAdd type={''} onClickFunc={onAddClick} />

                </div>
            </div>
            <InputSaveOnEdit
                value={value}
                setValue={setValue}
                open={openInput}
                setOpen={setOpenInput}
                save={saveUserOnTeam}
                typeInput='list'
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                selectedRole={selectedRole}
                setSelectedRole={setSelectedRole}
                persons={persons}
                roles={roles}
            />
            <div ref={curRef} onScroll={onScroll} className="custom_table">
                <table>
                    <tbody>
                        {members && members.length ? members.map(member => (
                            <tr>
                                <td className='team_checkbox'><Checkbox id={member.id} roleId={member.roleId} handleSelection={handleTeamSelection} />{personRowDisplay(member)}</td>
                                <td>{member.position}</td>
                            </tr>
                        )) : ''}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default observer(Team);