import './MisceleniousStyles.css'

const PersonLineDisplay = (params: any) => {
    return (
        <div className='line-person-display-widget nonselectable'>
            <div className='line-profile-image-container'>
                {/*<img className='line-profile-image' src={params.picture}/>*/}
                <img className='line-profile-image' src='https://pofoto.club/uploads/posts/2022-09/1663019291_16-pofoto-club-p-kanadskii-bober-18.jpg'/>
            </div>
            <div title={params.name} className='task-type-text ellipses'>{params.name}</div>
        </div>

    );
}
export default PersonLineDisplay;
