import {useContext, createContext, useEffect, useState} from 'react';
import { observer } from "mobx-react-lite";
import "../styles/menu.pc.css";
import './LeftMenu.css'
import '../../globalstyles/ControlElementsStyles.css'
import { Context } from "../../index";
import Logo from '../../assets/logo.svg';
import { Tree } from 'primereact/tree';
import { AppContext } from '../../App';
import InfoPanel from './InfoPanel/InfoPanel';
import {useLocation, useNavigate} from "react-router-dom";
import { TreeNode } from '../../models/response/LeftMenuResponse';


export const MenuFormContext = createContext<any>(null)
const MenuForm = () => {
    const { store } = useContext(Context);
    const navigate = useNavigate();
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const [nodesForView, setNodesForView] = useState<TreeNode[]>([]);
    const [hrefLogoCompany, setHrefLogoCompany] = useState<string | undefined>("");
    const { setBreadCrumb, infoPanelCount, setinfoPanelCount,
        setSelectedKeys,
        setExpandedKeys,
        onSelect,
        metaKey,
        nodes,
        expandedKeys,
        selectedKeys
    } = useContext(AppContext)

    useEffect(() => {
        /** structuredClone не работает, т.к. nodes это Proxy массив, не нашел вариантов обойти JSON.parse(JSON.stringify(...)) */
        let nodesCopy: TreeNode[] = JSON.parse(JSON.stringify(nodes));
        if (!store.showProxyBoards) {
            nodesCopy = nodesCopy.filter(function f(n: TreeNode): boolean {
                if (n.children)
                    n.children = n.children.filter(f)
                return !n.isProxyBoard;
            })
        }
        setNodesForView(nodesCopy);
    }, [store.showProxyBoards, nodes])

    const handleLogout = async () => {
        try {
            const success: boolean | undefined = await store.logout();
            if (success) {
                setIsLoggedOut(true);
                navigate("/login")
            } else {
                console.error('Logout failed');
            }
        } catch (error) {
            console.error('Logout failed', error);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const data = await store.getSystemSettings();

                if (data) {
                    let logo = data.find(xx => xx.paramName == "logo");
                    if (logo) {
                        setHrefLogoCompany(logo.fileId);
                    } else {
                        setHrefLogoCompany(Logo);
                    }
                } else {
                    setHrefLogoCompany(Logo);
                }
            } catch (error) {
                console.log(error)
            }
        })();
    }, []);

    return (
        <div className="menuForm__component">
            <div className="menuForm__component--head">
                <div className="company-logo-name">
                    <img src={hrefLogoCompany}></img>
                </div>

                <div className="card flex justify-content-center">
                    <div className='panelForProject'>
                        <InfoPanel count={infoPanelCount} idKey={'projectCount'}/>
                    </div>
                    {/* <div className='panelForChats'>
                        <InfoPanel />
                    </div> */}
                    <Tree selectionMode="multiple" metaKeySelection={metaKey} value={nodesForView} expandedKeys={expandedKeys}
                        selectionKeys={selectedKeys}
                        onSelectionChange={(e: any) => setSelectedKeys(e.value)}
                        onToggle={(e: any) => setExpandedKeys(e.value)}
                        onSelect={onSelect} className="w-full md:w-30rem"
                    />
                </div>
            </div>
            <div className='bottom-group'>
                <button onClick={() => handleLogout()}>
                    Выйти
                </button>
                <div>
                    <label>Показывать прокси-доски</label>
                    <input
                        type='checkbox'
                        checked={store.showProxyBoards}
                        onChange={(e) => {store.setShowProxyBoards(!store.showProxyBoards);}}/>
                </div>
                <button onClick={() => navigate("/feedback")}>
                    Обратная связь
                </button>
                <p>
                    <a className='wiki-link'>Руководство пользователя</a>
                </p>
            </div>
        </div>
    )

}


export default observer(MenuForm);