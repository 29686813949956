import React, { FC, useContext, useEffect } from "react";
// import ForgotPasswordForm from "../../component/Authorization/ForgotPassword/ForgotPasswordForm";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import { Route, useLocation, useNavigate } from "react-router-dom";
import Confirmation from "../../component/Authorization/Confirmation/Confirmation";
import ForgotPasswordForm from "../../component/Authorization/ForgotPassword/ForgotPasswordForm";

//to commit
const ForgotPassword: FC = (index) => {
    const { store } = useContext(Context);
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("token")) {
            store.chekAuth();
        }

        if (store.isAuth && store.isActivate) {
            navigate("/projects");
        }
    }, [store.isAuth, store.isActivate]);

    if (store.isLoading) {
        return (
            <div>
                <h1>Загрузка...</h1>
            </div>
        );
    } else if (!store.isAuth) {
        return (
            <div>
                <ForgotPasswordForm />
            </div>
        );
    } else if (!store.isActivate) {
        return (
            <div>
                <Confirmation />
            </div>
        );
    } else {
        return null;
    }
};

export default observer(ForgotPassword);
