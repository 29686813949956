import React, { FC, useContext, useEffect } from 'react';
import { observer } from "mobx-react-lite";
// import "../../styles/dashboard.pc.css";
import DeleteElement from "../../../assets/deleteElementGray.svg"

export interface IDeleteButtonProps {
    onClickFunc: () => void;
}

const BtnDelete: FC<IDeleteButtonProps> = ({onClickFunc}) => {
    return (
        <div className="button-base btn_margin-left" onClick={onClickFunc}>
            <img src={DeleteElement} />
        </div>
    )
}

export default observer(BtnDelete);