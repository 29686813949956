import React, { FC, useContext, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import checkMark from "../../../assets/CheckMarkGray.svg"
import checkMarkWhite from "../../../assets/CheckMarkWhite.svg"
import '../BoardActionModeButton/ActionModeButton.css'

export interface IActionModeButtonProps {
    onClickFunc: () => void;
    isActive: boolean;
    selectedCount: number;
}

const BtnActionMode: FC<IActionModeButtonProps> = ({onClickFunc, isActive, selectedCount}) => {
    const check = isActive ? checkMarkWhite : checkMark
    let className = "action-mode-button "

    if(isActive){ className += "active " }
    if(selectedCount == 0) {
        className += "empty "
        return (
            <div className={className} onClick={onClickFunc}>
                <img style={{width: "18px", height: "18px"}} src={check} />
            </div>
        )
    } else {
        return (
            <div className={className} onClick={onClickFunc}>
                <img style={{width: "18px", height: "18px"}} src={check} />
            </div>
        )
    }
}

export default observer(BtnActionMode);