import React, { useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import "../../styles/filesComponent.pc.css";
import "../../styles/widjets.pc.css";
import "./nameProject.pc.css";
import "./nameProject.pc.css";
import "primereact/resources/themes/lara-light-blue/theme.css";
import { TabView, TabPanel } from 'primereact/tabview';
import { IProjectDeleteReq, IProjectEditReq, IProjectGeneralInfo } from "../../../models/ProjectModels";
import { Context } from "../../../index";
import { Link, useNavigate, useParams } from "react-router-dom";
import CopyTextButton from "../../buttons/CopyTextButton/CopyTextButton";
import BtnEdit from '../../buttons/btnEdit.component/BtnEdit';
import { AppContext } from '../../../App';
import InputSaveOnEdit from "../InputSaveOnWidjet/InputSaveOnWidjet"
import BtnMore from '../../buttons/btnMore.component/BtnMore';
import EditProject from '../../Shared/AddEditProject/AddEditProject';
import BtnAdd from '../../buttons/btnAdd.component/BtnAdd';
import useOutsideAlerter from '../../../customHooks/useOutsideAlert';
import ConfirmationWindow from '../../Shared/ConfirmationWindow/ConfirmationWindow';
import CloseBlockWindow from "../../../assets/arrowCloseWindow.svg";
import OpenBlock from "../../../assets/openBlock.svg";

interface IProjectNameProps {
    data: IProjectGeneralInfo | undefined;
    loadNameProjectData: () => void;
}

const NameProject: React.FC<IProjectNameProps> = ({ data, loadNameProjectData }) => {
    const { store } = useContext(Context);
    const { getData } = useContext(AppContext);
    const [open, setOpen] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const [dataForEdit, setDataForEdit] = useState(['Отредактировать проект', 'Удалить проект'])
    const [openInputProject, setOpenInputProject] = useState(false);
    const [openInputBoard, setOpenInputBoard] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const [activeIndex, setActiveIndex] = useState(0)
    const [valueProj, setValueProj] = useState('');
    const [valueBoard, setValueBoard] = useState('');
    const [openConfirmWindow, setOpenConfirmWindow] = useState(false);
    const [openOnFullWindow, setOpenOnFullWindow] = useState(false);

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (event.key === 'Escape') {
                event.preventDefault();
                setOpenInputProject(false);
                setOpenInputBoard(false);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        // Clean up event listener
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);
    const copyProjectNumber = async () => {
        const text = document.getElementById('general-info-widget-project-number')?.innerText;
        if (!text) { return }
        await navigator.clipboard.writeText(text);
    }
    //
    // function onMoreClick(){
    //
    // }

    function onAddClick() {
        if (activeIndex === 2) {
            setOpenInputBoard(true);
        } else if (activeIndex === 1) {
            setOpenInputProject(true);
        }
    }

    function onEditClick(e: any) {
        setOpen(false);
        if (e.target.innerText === 'Отредактировать проект') {
            setActiveIndex(0);
            setOpenEdit(true);
        } else if (e.target.innerText === 'Удалить проект') {
            setOpenConfirmWindow(true);
        }
    }

    function saveBoard(e: any) {
        e.preventDefault();
        let nameBoard = valueBoard;
        id && (async () => {
            await store.addDashBoard(nameBoard, Number(id), 1);
            await loadNameProjectData();
            await loadNameProjectData();
            getData();
            setValueBoard('');
            setOpenInputBoard(false)
        })()
    }
    function saveProject() {
        let nameProject = valueProj;
        let startDate = new Date;
        let allottedTime = 4;
        let description = "";
        let shortDescription = nameProject;
        let parentProjectId = Number(id);
        id && (async () => {
            await store.addProject(1, undefined, startDate, allottedTime,
                description, shortDescription, parentProjectId);
            await loadNameProjectData();
            await getData();
            setValueProj('');
            setOpenInputProject(false)
        })()
    }

    const onDelete = () => {
        let body: IProjectDeleteReq = {
            projectId: Number(id)
        };
        (async () => {
            await store.deleteProject(body);
            await getData();
        })();
    }

    const onConfirm = () => {
        onDelete();
        setOpenConfirmWindow(false);
        navigate('/projects');
    }
    const onCancel = () => {
        setOpenConfirmWindow(false);
    }

    const openConfWindow = () => {
        setOpenConfirmWindow(true);
    }

    return (
        <div className={openOnFullWindow ? 'widget_wrapper--full__window' : 'widget_wrapper'}>
            <div className={openOnFullWindow ? 'widget_wrapper-full__window--block' : ''}>
                {openConfirmWindow && <ConfirmationWindow headerText='Удалить проект?'
                    bodyText='Вы уверены, что хотите удалить этот проект?'
                    confirmText='Да' cancelText='Нет'
                    onConfirm={onConfirm} onCancel={onCancel} />}
                {openEdit &&
                    <EditProject
                        setOpen={setOpenEdit}
                        projectInfo={data ? {name: data.projectName, description: data.description, startDate: new Date()} : undefined} />}
                <div className='widgets_header'>
                    {openOnFullWindow ?
                        <div onClick={() => setOpenOnFullWindow(false)} className='widgets_header--open__block'>
                            <img src={CloseBlockWindow}/>
                        </div>
                        :
                        <div onClick={() => setOpenOnFullWindow(true)} className='widgets_header--open__block'>
                            <img src={OpenBlock}/>
                        </div>
                    }
                    <div className="widjets_header_left">
                        <h2 className='widget-card-header-style'>
                            {data?.projectName}
                        </h2>
                    </div>
                    <div className='widjets_header_rigth'>

                        {activeIndex === 0 ? <BtnMore onClickFunc={onEditClick}
                            data={dataForEdit} typeForKey='nameProj'
                            open={open} setOpen={setOpen} /> :
                            <BtnAdd type={''} onClickFunc={() => onAddClick()} />}
                    </div>
                </div>
                <div className='nameProj-sub-header'>
                    <p id='general-info-widget-project-number' style={{ marginLeft: "10px" }} className='nameProj_p_11_sub_header_widgets'>{data?.number}</p>
                    <CopyTextButton textDivId='general-info-widget-project-number' />
                </div>
                <div className='nameProj_body'>
                    <div>
                        <div>
                            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                <TabPanel header="Описание">
                                    <p className="m-0" style={{ display: "flex", flexDirection: "column", height: "100px", overflowY: "auto" }}>
                                        {data?.description}
                                    </p>
                                </TabPanel>
                                {data?.parentProjectId ? <TabPanel header="Подпроекты" disabled></TabPanel> :
                                    <TabPanel header="Подпроекты"  >
                                        <p className="m-0">
                                            <div style={{ display: "flex", flexDirection: "column", height: "100px", overflowY: "auto" }}>
                                                <InputSaveOnEdit
                                                    value={valueProj}
                                                    setValue={setValueProj}
                                                    open={openInputProject}
                                                    setOpen={setOpenInputProject}
                                                    save={saveProject}
                                                    typeInput='text'
                                                />
                                                {data?.childProjects?.map((item, index) => (
                                                    <div key={item.id} style={{ display: "flex", flexDirection: "row", padding: "5px" }}>
                                                        <Link to={`/project/${item.id}`}>{item.name}</Link>
                                                        <div className='endData' style={{ marginLeft: "auto", display: "flex", flexDirection: "row" }}>
                                                            <div style={{ padding: "5px" }}>Доски: {item.boardCount}</div>
                                                            <div style={{ padding: "5px" }}>Задачи: {item.taskCount}</div>
                                                        </div>

                                                    </div>
                                                ))}
                                            </div>
                                        </p>
                                    </TabPanel>
                                }

                                <TabPanel header="Канбан доски">
                                    <p className="m-0">
                                        <div style={{ display: "flex", flexDirection: "column", height: "100px", overflowY: "auto" }}>


                                            <InputSaveOnEdit
                                                value={valueBoard}
                                                setValue={setValueBoard}
                                                open={openInputBoard}
                                                setOpen={setOpenInputBoard}
                                                save={saveBoard}
                                                typeInput='text'
                                            />
                                            {data?.boards?.map((item, index) => (
                                                <div key={item.id} style={{ display: "flex", flexDirection: "row", padding: "5px" }}>
                                                    <Link to={`/board/${item.id}`}>{item.name}</Link>
                                                    <div className='endData' style={{ marginLeft: "auto", display: "flex", flexDirection: "row" }}>
                                                        <div style={{ padding: "5px" }}>Задачи: {item.taskCount}</div>
                                                    </div>

                                                </div>
                                            ))}
                                        </div>
                                    </p>
                                </TabPanel>
                            </TabView>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default observer(NameProject);
