import React, { FC, useContext, useState } from "react";
import { Context } from "../../index";
// import "../../styles/form.pc.css"
// import "../../../globalstyles/input.pc.css"
// import "../../../globalstyles/background.pc.css"
// import "../../../globalstyles/button.pc.css"
// import "../../../globalstyles/text.pc.css"
import { observer } from "mobx-react-lite";
import CustomLink from "../Shared/CustomLink/CustomLink";
import Logo from "../../assets/mainLogo.svg";
import Background from "../../assets/backgroundLogin.jpg";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "./FeedbackForm.css";

// Логика, завязанная на userLoggedIn используется
// если форма обратной связи может быть отображена
// пользователей, которые вошли и пользователей,
// которые не вошли в систему

interface IFeedbackFormProps {
    userLoggedIn: boolean;
}

const FeedbackForm: FC<IFeedbackFormProps> = ({ userLoggedIn }) => {
    const QUESTION_LIMIT = 500;

    const [email, setEmail] = useState<string>("");
    const [question, setQuestion] = useState<string>("");
    const [emailError, setEmailError] = useState<string>();
    const [questionError, setQuestionError] = useState<string>();
    const [infoMode, setInfoMode] = useState<boolean>(false);
    const { store } = useContext(Context);

    const handleConfirm = async () => {
        const emailErr = validateEmail();
        const questionErr = validateQuestion();

        if (emailErr || questionErr) return;

        try {
            const res = await store.addFeedback({
                email: email,
                text: question,
            });
            setInfoMode(true);
        } catch (err) {}
    };

    const validateEmail = (): boolean => {
        let emailErr = undefined;

        if (!email) {
            emailErr = "Введите email";
        } else if (!isValidEmail(email)) {
            emailErr = "Некорректный email";
        }
        setEmailError(emailErr);
        return emailErr !== undefined;
    };

    const validateQuestion = (): boolean => {
        let questionErr = undefined;

        if (!question) {
            questionErr = "Введите вопрос";
        }
        setQuestionError(questionErr);
        return questionErr !== undefined;
    };

    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    if (infoMode) {
        return (
            <div className="form_main_center">
                <div className="form_container form_container_fullscreen form_container_center">
                    <div className="form_logo">
                        <img src={Logo} alt="Логотип компании" />
                    </div>
                    <div>
                        <h2 className="form_header form_text">Запрос успешно отправлен</h2>
                    </div>
                    <div className="form_description">
                        <p className="form_text">Мы свяжемся с вами в ближайшее время</p>
                    </div>
                    {userLoggedIn ? (
                        <div className="bottom_buttons">
                            <p className="p_blue p_14 pointer_custom">
                                <CustomLink to="/projects">Главная</CustomLink>
                            </p>
                        </div>
                    ) : (
                        <div className="bottom_buttons">
                            <p className="p_blue p_14 pointer_custom">
                                <CustomLink to="/login">Вход</CustomLink>
                            </p>
                            <p className="p_blue p_14 pointer_custom">
                                <CustomLink to="/">Регистрация</CustomLink>
                            </p>
                        </div>
                    )}
                </div>
                <img
                    src={Background}
                    alt="Фон компании"
                    className="background_image_fullscreen"
                />
            </div>
        );
    }

    return (
        <div className="form_main_center">
            <div className="form_container form_container_fullscreen form_container_center">
                <div className="form_inner">
                    <div className="form_logo">
                        <img src={Logo} alt="Логотип компании" />
                    </div>
                    <div>
                        <h2 className="form_header form_text">Обратная связь</h2>
                    </div>
                    <div className="form_description">
                        <p className="form_text">
                            Подробно сформулируйте вопрос и мы свяжемся с вами в
                            ближайшее время
                        </p>
                    </div>
                    <div
                        className={`input_form_login ${
                            emailError ? "input_with_error" : ""
                        }`}
                    >
                        <label className="input_label feedback_label" htmlFor="login_email">
                            Email*
                        </label>
                        <InputText
                            placeholder="email"
                            id="login_email"
                            value={email}
                            onBlur={validateEmail}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => setEmail(e.target.value)}
                        />
                    </div>
                    {emailError ? (
                        <p className="error_message">{emailError}</p>
                    ) : null}
                    <div
                        className="input_form_login"
                        style={{ marginBottom: "10px" }}
                    >
                        <label className="input_label feedback_label" htmlFor="login_email">
                            Вопрос*
                        </label>
                        <textarea
                            className={`feedback_textarea ${
                                questionError ? "textarea_with_error" : ""
                            }`}
                            value={
                                question?.length! > QUESTION_LIMIT
                                    ? question?.slice(0, QUESTION_LIMIT)
                                    : question
                            }
                            onBlur={validateQuestion}
                            style={{ width: "100%", resize: "none" }}
                            id="question-input"
                            cols={30}
                            rows={2}
                            onChange={(e) => setQuestion(e.target.value)}
                            placeholder="Сформируйте вопрос"
                        ></textarea>
                    </div>
                    {questionError ? (
                        <p className="error_message">{questionError}</p>
                    ) : null}
                    <div className="login_btn">
                        <Button
                            className="login_btn"
                            label="Отправить"
                            onClick={handleConfirm}
                        />
                    </div>
                    {userLoggedIn ? (
                        <div className="bottom_buttons form__margin-top">
                            <p className="p_blue p_14 pointer_custom ">
                                <CustomLink to="/projects">Главная</CustomLink>
                            </p>
                        </div>
                    ) : (
                        <div className="bottom_buttons form__margin-top">
                            <p className="p_blue p_14 pointer_custom">
                                <CustomLink to="/login">Вход</CustomLink>
                            </p>
                            <p className="p_blue p_14 pointer_custom">
                                <CustomLink to="/">Регистрация</CustomLink>
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <img
                src={Background}
                alt="Фон компании"
                className="background_image_fullscreen"
            />
        </div>
    );
};

export default observer(FeedbackForm);
