import React from 'react';
import { useNavigate } from 'react-router-dom';

interface CustomLinkProps {
    to: string; // Specify that 'to' is a string
    children: React.ReactNode; // Specify that 'children' is a React node
}

const CustomLink: React.FC<CustomLinkProps> = ({ to, children }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(to);
    };

    return (
        <span className="a_18-white__none-hover" onClick={handleClick}>{children}</span>
    );
};

export default CustomLink;