import React, { useContext, useState } from "react";
import { Context } from "../../../index";
import "../../styles/form.pc.css";
// import "../../../globalstyles/input.pc.css"
// import "../../../globalstyles/background.pc.css"
// import "../../../globalstyles/button.pc.css"
// import "../../../globalstyles/text.pc.css"

import { observer } from "mobx-react-lite";
import CustomLink from "../../Shared/CustomLink/CustomLink";
import Logo from "../../../assets/mainLogo.svg";
import Bacground from "../../../assets/backgroundLogin.jpg";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import { Password } from "primereact/password";

const RecoverPasswordForm = () => {
    const { token } = useParams();
    const MIN_PASSWORD_LENGTH = 8;

    const [password, setPassword] = useState<string>("");
    const [passwordRepeat, setPasswordRepeat] = useState<string>("");
    const [passwordError, setPasswordError] = useState<string>();
    const [passwordRepeatError, setPasswordRepeatError] = useState<string>();
    const [infoView, setInfoView] = useState<boolean>(false);
    const [info, setInfo] = useState<string>("");
    const { store } = useContext(Context);

    const navigate = useNavigate();

    const handleRecover = async () => {
        const passwordErr = validatePassword();
        const passwordRepeatErr = validateRepeatPassword();

        if (passwordErr || passwordRepeatErr) return;

        try {
            const error = await store.recoverPassword({
                password: password,
                token: token!,
            });
            setInfoView(true);
            if (!error) {
                setInfo("Пароль успешно изменен");
            } else {
                setInfo(error.message);
            }
        } catch (err) {}
    };

    const validatePassword = (): boolean => {
        let passwordErr = undefined;

        if (!password) {
            passwordErr = "Введите пароль";
        } else if (password.length < MIN_PASSWORD_LENGTH) {
            passwordErr = `Минимальная длина пароля - ${MIN_PASSWORD_LENGTH} символов`;
        }

        setPasswordError(passwordErr);
        return passwordErr !== undefined;
    };

    const validateRepeatPassword = (): boolean => {
        let passwordRepeatErr = undefined;

        if (!passwordRepeat) {
            passwordRepeatErr = "Введите подтверждение пароля";
        } else if (passwordRepeat !== password) {
            passwordRepeatErr = `Пароли не совпадают`;
        }

        setPasswordRepeatError(passwordRepeatErr);
        return passwordRepeatErr !== undefined;
    };

    return (
        <div className="form_main_center">
            <div className="form_container form_container_fullscreen form_container_center">
                <div className="form_inner">
                    <div className="form_logo">
                        <img src={Logo} alt="Логотип компании" />
                    </div>
                    <div>
                        <h2 className="form_header form_text">
                            Восстановление пароля
                        </h2>
                    </div>
                    {infoView ? (
                        <>
                            <div className="form_description">
                                <p className="form_text">{info}</p>
                            </div>
                            <div className="bottom_buttons">
                                <p className="p_blue p_14 pointer_custom">
                                    <CustomLink to="/login">Вход</CustomLink>
                                </p>
                            </div>
                        </>
                    ) : (
                        <div>
                            <div
                                className={`input_form_login ${
                                    passwordError ? "input_with_error" : ""
                                }`}
                            >
                                <label
                                    className="input_label"
                                    htmlFor="login_pass"
                                >
                                    Новый пароль*
                                </label>
                                <Password
                                    id="login_pass"
                                    value={password}
                                    onBlur={validatePassword}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => setPassword(e.target.value)}
                                    feedback={false}
                                    placeholder="********"
                                    toggleMask
                                />
                                {passwordError ? (
                                    <p className="error_message">
                                        {passwordError}
                                    </p>
                                ) : null}
                            </div>
                            <div
                                className={`input_form_login ${
                                    passwordRepeatError
                                        ? "input_with_error"
                                        : ""
                                }`}
                            >
                                <label
                                    className="input_label"
                                    htmlFor="login_pass"
                                >
                                    Подтверждение пароля*
                                </label>
                                <Password
                                    id="login_pass_repeat"
                                    value={passwordRepeat}
                                    onBlur={validateRepeatPassword}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => setPasswordRepeat(e.target.value)}
                                    feedback={false}
                                    placeholder="********"
                                    toggleMask
                                />
                                {passwordRepeatError ? (
                                    <p className="error_message">
                                        {passwordRepeatError}
                                    </p>
                                ) : null}
                            </div>
                            <div className="login_btn">
                                <Button
                                    className="login_btn"
                                    label="Восстановить"
                                    onClick={handleRecover}
                                />
                            </div>
                            <div className="bottom_buttons form__margin-top">
                                <p className="p_blue p_14 pointer_custom">
                                    <CustomLink to="/login">Вход</CustomLink>
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <img
                src={Bacground}
                alt="Фон компании"
                className="background_image_fullscreen"
            />
        </div>
    );
};

export default observer(RecoverPasswordForm);
