import React, {FC} from 'react';
import {observer} from "mobx-react-lite";
import "./SystemSettings.css"
import BasicTab from "../../component/SystemSettings/BasicTab/BasicTab";

const SystemSettings: FC = (index) => {
    const [tab, setTab] = React.useState<number>(0);
    const tabs: string[] = ["Основные", "Пользователи", "Список ролей"]

    function changePage(id: number) {
        setTab(id);
    }

    return (
        <div className="system__settings">
            <div className="system__settings--header">
                <div className="system__settings--header__tabs">
                    {tabs.map((tabName, i) => (
                        <button className={`system__settings--header__button ${tab == i ? "system__settings--header__button__selected" : ""}`} onClick={() => (changePage(i))}>
                            {tabName}
                        </button>
                    ))}
                </div>
            </div>
            <div className="system__settings--body">
                {tab == 0 ?
                    <div>
                        <BasicTab />
                    </div>
                : tab == 1 ?
                    <div>

                    </div>
                :
                    <div>

                    </div>
                }
            </div>
        </div>
    );
};

export default observer(SystemSettings);