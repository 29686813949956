import React, { FC, useContext, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import settingsCog from "../../../assets/SettingCogBlue.svg"

export interface ISettingsButtonProps {
    onClickFunc: () => void;
}

const BtnSettings: FC<ISettingsButtonProps> = ({onClickFunc}) => {
    return (
        <div className="button-base" onClick={onClickFunc}>
            <img src={settingsCog} />
        </div>
    )
}

export default observer(BtnSettings);