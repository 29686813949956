import React, { useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import "../styles/header.pc.css";

import Settings from "../../assets/settings.svg";
import Notification from "../../assets/notification.svg";
import Avatar from '../Shared/Avatar/Avatar';
import '../Header/Header.css'
import HeadNamePage from './HeadNamePage/HeadNamePage';
import { useNavigate } from "react-router-dom";

const Header = () => {
    const { store } = useContext(Context);
    const currentHref = window.location.pathname;
    const navigate = useNavigate();
    const [name, setName] = useState('');

    // const User: any = store.getUser(localStorage.getItem("email")!)

    useEffect(() => {
        const fetchUser = async () => {
            const email = localStorage.getItem("email");
            if (email) {
                await store.getUser(email);
            }
        };

        if (!store.isAuth) {
            fetchUser();
        }
    }, [store]);

    function goToSettings() {
        navigate("/system/settings");
    }


    return (
        <div className="header-component">
            <HeadNamePage />
            <div id='right-control-panel'>
                <button className='button_reset_style' onClick={goToSettings}>
                    <img className='button_settings' src={Settings} alt="" />
                </button>
                <button className='button_reset_style'>
                    <div className='header_icon_notification'>
                        <img className='button_notification' src={Notification} alt="" />
                    </div>
                </button>
                <div className="header__component-text">
                    <a className="a_header_name_16">
                    {store.user.name} {store.user.surname}</a>
                </div>
                <Avatar />
            </div>
        </div>
    );

}

export default observer(Header);



