import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";
import { useParams } from 'react-router-dom';
import "../../styles/filesComponent.pc.css";
import { Context } from "../../../index";
import '../../Project/Files/Files.css';
import './TaskFiles.css';
import {
    IFileFilterOptions,
    IFileFilters,
    IFilesDisplay,
    IFileTaskFilters,
    IGetFilesTaskDisplayData
} from "../../../models/FileModels";
import BtnDownload from "../../buttons/btnDownload.component/BtnDownload";
import FileImage from "../../../assets/fileImage.svg"
import FileVideo from  "../../../assets/fileVideo.svg"
import FileDocument from "../../../assets/fileDocument.svg"
import BtnAdd from "../../buttons/btnAdd.component/BtnAdd";
import TaskBtnAdd from "../TaskBtnAdd/TaskBtnAdd";

const TaskFiles: FC = () => {
    const { store } = useContext(Context);
    const { id } = useParams();
    const [deletFilesIds, setDeletFilesIds] = useState<number[]>([]);
    const [files, setFiles] = useState<IFilesDisplay | undefined>(undefined);
    const [filesFilterOptions, setFilesFilterOptions] = useState<IFileFilterOptions | undefined>(undefined);
    const filters = useRef<IFileFilters>({
        projectId: 1,
        fileType: [],
        name: ''
    })

    const loadFiles = async (filters: IFileTaskFilters) => {
        let req: IGetFilesTaskDisplayData = {
            filters: filters
        }
        const res = await store.getFilesTaskDisplayData(req) as IFilesDisplay;
        setFiles(res);
    };

    function onDownloadClick(itemId: number, type: string, name: string) {
        (async () => {
            try {

            } catch (error) {
                console.error('Download error:', error);
            }
        })();
    }

    function onDeleteClick() {
        deletFilesIds?.length && (async () => {
            await store.deleteFiles(deletFilesIds);
            await loadFiles({
                taskId: Number(id),
                name: '',
                fileType: []
            })
        })();
    };

    function onAddClick(e: any) {
        (async () => {
            await store.addFileChat(e.target.files[0], Number(id), 1);
            console.log(e.target.files[0])
            await loadFiles({
                taskId: Number(id),
                name: '',
                fileType: []
            })

        })();
    };

    const handleFileSelection = (fileId: number, event: any) => {
        let ids = deletFilesIds;
        if (event.target.checked) {
            ids.push(fileId);
        } else {
            let index = ids.findIndex(i => i === fileId);
            if (index !== -1) {
                ids.splice(index, 1);
            }
        }
        setDeletFilesIds(ids);
    };

    useEffect(() => {
        (async () => {
            await loadFiles({
                taskId: Number(id),
                name: '',
                fileType: []
            })
        })();
    },[id, store.updateFile]);

    return (
        <div className="task__component--right__menu">
            <div className="task__component--right__menu--files">
                <div className="task__component--right__menu--files__name">
                    Файлы - {files?.totalRecords}
                </div>
                <div className="task__component--right__menu--files__btn">
                    <TaskBtnAdd type={'file'} onClickFunc={onAddClick} />
                </div>
            </div>
            <div>
                <div id='files-widget-items-container'  className="files-widget-items-container__scroll">
                    {files?.files?.map((item, index) => (
                        <div key={item.id} className="files-widget-items-container__block">
                            <div className="files-widget-items-container__img">
                                {item.type == '.png' || item.type == '.jpg' || item.type == '.jpeg' ?
                                    <img src={FileImage}/>
                                : item.type == '.mpg' || item.type == '.mp4' || item.type == '.mpeg' ?
                                    <img src={FileVideo}/>
                                :
                                    <img src={FileDocument}/>
                                }
                            </div>
                            <div className='files-widget-file_item_name--text'>
                                <div title={`${item.name}${item.type}`} className='files-widget-file_item_name--text__curent'>{item.name.length+item.type.length > 15 ?
                                    item.name.slice(0,15)+"..." :
                                    item.name+item.type}</div>
                            </div>
                            <div style={{display: "flex", verticalAlign: "middle", marginLeft: "auto"}} className="task__component--right__menu--files__size">
                                <BtnDownload onClickFunc={onDownloadClick} itemId={item.id} url={item.url} type={item.type} name={item.name}/>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    )
}

export default observer(TaskFiles);