import $api from "../http";
import {AxiosResponse} from "axios";
import {ProjectResponse, ProjectsResponse} from "../models/response/ProjectResponse";
import {
    IGetProjectHistoryDisplay, IProjectChangesDisplay,
    IProjectGeneralInfo,
    IProjectProgressInfo
} from "../models/ProjectModels";
import {IAddTeamDisplay, IDeleteTeamDisplay} from "../models/TeamModels";

export default class TeamService {
    static async addTeamMembers(body: IAddTeamDisplay): Promise<AxiosResponse<IAddTeamDisplay>> {
        return $api.post<IAddTeamDisplay>('/Project/addTeamMembers', body);
    }

    static async deleteTeamMembers(body: IDeleteTeamDisplay): Promise<AxiosResponse<IDeleteTeamDisplay>> {
        return $api.post<IDeleteTeamDisplay>(`/Project/deleteTeamMembers`, body); 
    }

}