import { FC, useContext, useState } from "react";
import "../../styles/modalWindow.css";
import { TabPanel, TabView } from "primereact/tabview";
import { IProjectCreateReq, IProjectEditReq } from "../../../models/ProjectModels";
import { useParams } from "react-router-dom";
import { Context } from "../../..";
import useOutsideAlerter from "../../../customHooks/useOutsideAlert";
import "../../Task/TaskAddEditForm/TaskAddEditForm.css";
import "./AddEditProject.css";
import { AppContext } from "../../../App";
import { FloatLabel } from "primereact/floatlabel";
import { Calendar } from "primereact/calendar";
import Checkbox from "../Checkbox/Checkbox";

//TODO нужно продумать из какого запроса будет приходить информация и куда поместить этот интерфейс
interface IProjectInfo {
    name: string;
    description?: string;
    startDate: Date;
    endDate?: Date;
    //TODO добавить команду проекта
}

interface IEditProject {
    setOpen: (open: boolean) => void;
    projectInfo?: IProjectInfo;
}

const EditProject: FC<IEditProject> = ({ setOpen, projectInfo }) => {
    const { store } = useContext(Context);
    const { id } = useParams();
    const { getData } = useContext(AppContext);
    const [activeIndex, setActiveIndex] = useState(0);
    const [name, setName] = useState<string | undefined>(projectInfo?.name);
    const [nameError, setNameError] = useState<string>();
    const [description, setDescription] = useState<
        string | undefined
    >(projectInfo?.description);
    const [startDate, setStartDate] = useState<Date | undefined>(projectInfo?.startDate);
    const [endDate, setEndDate] = useState<Date | undefined>(projectInfo?.endDate);
    const [endDateEmpty, setEndDateEmpty] = useState<boolean>(false);
    const [datesError, setDatesError] = useState<string>();

    const EDIT_MODE = projectInfo !== undefined;

    const onCreate = () => {
        const nameErr = validateName();
        const datesErr = validateDates();

        if (nameErr || datesErr)
            return;

        const body: IProjectCreateReq = {
            name: name!,
            description: description,
            startDate: startDate!,
            endDate: endDateEmpty ? null : endDate!
        };

        (async () => {
            await store.createProject(body);
            // await store.getProjectGeneralInfo(Number(id));
            await getData();
        })();
        setOpen(false);
    }

    const onEdit = () => {
        const nameErr = validateName();
        const datesErr = validateDates();

        if (nameErr || datesErr)
            return;

        const body: IProjectEditReq = {
            projectId: Number(id),
            name: name,
            description: description,
            startDate: startDate,
            endDate: endDateEmpty ? null : endDate!
        };
        (async () => {
            await store.editProject(body);
            await store.getProjectGeneralInfo(Number(id));
            await getData();
        })();
        setOpen(false);
    }

    const validateName = (): boolean => {
        let nameErr = undefined;

        if (!name || !name.length) {
            nameErr = 'Название проекта не заполнено';
        }

        setNameError(nameErr);
        return nameErr !== undefined;
    }

    const validateDates = (): boolean => {
        let datesErr = undefined;

        if (!startDate && !endDateEmpty && !endDate)
            datesErr = "Заполните сроки проекта";
        else if (startDate && endDate && endDate < startDate)
            datesErr = "Дата начала не может быть больше даты окончания";
        else if (!startDate)
            datesErr = "Заполните дату начала проекта";
        else if (!endDateEmpty && !endDate)
            datesErr = "Заполните дату окончания проекта";

        setDatesError(datesErr);

        return datesErr !== undefined;
    }


    const outsideAlerterRef: any = useOutsideAlerter(() => setOpen(false));
    return (
        <div className="modal-window-overlay">
            <div className="modal-window-container" ref={outsideAlerterRef}>
                <TabView
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                >
                    <TabPanel header="Основное">
                        <label
                            className="input_label label_24"
                            htmlFor="name_input"
                        >
                            Название проекта*
                        </label>
                        <input
                            id="name_input"
                            className={`input_form label_24 ${nameError ? "textarea_with_error" : ""}`}
                            onChange={(e: any) => setName(e.target.value)}
                            onBlur={validateName}
                            type="text"
                            placeholder="Введите название проекта"
                            value={name}
                        />
                        {nameError ? (
                            <p className="error_message">{nameError}</p>
                        ) : null}
                        <label
                            className="input_label label_24"
                            htmlFor="desk_input"
                        >
                            Описание проекта
                        </label>
                        <textarea
                            className="input_form textarea_input label_24"
                            value={description}
                            placeholder="Опишите свой проект"
                            id="desk_input"
                            onChange={(e: any) =>
                                setDescription(e.target.value)
                            }
                        ></textarea>
                    </TabPanel>

                    <TabPanel header="Команда"></TabPanel>

                    <TabPanel header="Сроки проекта">
                        <div
                            className={`calendar--block__flex ${
                                datesError ? "input_with_error" : ""
                            }`}
                        >
                            <div className="calendar--block__flex--inp">
                                <FloatLabel>
                                    <Calendar
                                        dateFormat="dd.mm.yy"
                                        onChange={(event) =>
                                            setStartDate(
                                                (event as any).target.value
                                            )
                                        }
                                        value={startDate}
                                    />
                                    <label htmlFor="Дата начала">
                                        Дата начала*
                                    </label>
                                </FloatLabel>
                            </div>
                            <div className="calendar--block__flex--inp">
                                <FloatLabel>
                                    <Calendar
                                        disabled={endDateEmpty}
                                        dateFormat="dd.mm.yy"
                                        onChange={(event) =>
                                            setEndDate(
                                                (event as any).target.value
                                            )
                                        }
                                        value={endDate}
                                    />
                                    <label htmlFor="Дата начала">
                                        Дата окончания
                                    </label>
                                </FloatLabel>
                            </div>
                        </div>
                        {datesError ? (
                            <p className="error_message" style={{marginTop: "5px"}}>{datesError}</p>
                        ) : null}
                        <div className="calendar--checkbox">
                            <Checkbox id={1} checked={endDateEmpty} handleSelection={() => {setEndDateEmpty(!endDateEmpty)}}/>
                            <label>Без даты окончания</label>
                        </div>
                    </TabPanel>
                </TabView>
                <div className="modal-window-btns">
                    <button
                        className="confirm_btn p_blue p_14 button_reset_style"
                        onClick={() => setOpen(false)}
                    >
                        Отмена
                    </button>
                    <button
                        className="modal-window-margin-btn p_white p_14 button_reset_style save_btn"
                        onClick={EDIT_MODE ? onEdit : onCreate}
                    >
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditProject;
