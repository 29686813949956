import React, { FC, useContext, useEffect, useRef } from 'react';
import { observer } from "mobx-react-lite";
import TaskIconBtn from "../../../assets/taskIconBtn.svg";
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import "./TaskBtnAdd.css"


export interface IAddButtonProps {
    onClickFunc: (e: any) => void;
    type: string;
}

const TaskBtnAdd: FC<IAddButtonProps> = ({ onClickFunc, type }) => {

    if (type == 'file') {
        return (
            <div>
                <label className="input-file input-file__task--size" >
                    <input onChange={(e) => { onClickFunc(e) }} type="file" />
                    <span className="input-file__task-btn">
                        <img src={TaskIconBtn} />
                    </span>
                </label>
            </div >
        )
    } else {
        return (
            <div key={type} className="button_add" onClick={onClickFunc}>
                <img src={TaskIconBtn} />
            </div>
        )
    }


}

export default observer(TaskBtnAdd);



