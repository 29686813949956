import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {observer} from "mobx-react-lite";
import './MisceleniousStyles.css'

const TaskStatus = (params: any) => {
    return (
        <div className='task-status-widget nonselectable'>
            <div className='task-type-text' style={{color: params.color}}>{params.name}</div>
        </div>

    );
}
export default TaskStatus;
