import $api from "../http";
import {AxiosResponse} from "axios";
import {AuthResponse} from "../models/response/AuthResponse";
import { IRecoverPassword } from "../models/IRecoverPassword";

export default class AuthService {
    static async login(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('/login', {email, password});
    }

    static async registration(email: string, password: string, name: string, surname: string, middlename: string): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('/registration', {email, password, name, surname, middlename});
    }

    static async logout(): Promise<AxiosResponse<void>> {
        return $api.post('/logout');
    }

    static async chekActivate(email: string): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('/chekActivate', {email});
    }

    static async fetchCurentUser(email: string): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('/getOneUser', {email});
    }

    static async requestPasswordRecover(email: string) {
        return $api.post("/requestPasswordRecover", {email});
    }

    static async recoverPassword(body: IRecoverPassword) {
        return $api.post("/recoverPassword", body);
    }
}