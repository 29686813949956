import React, { FC, useContext, useEffect, useRef } from 'react';
import { observer } from "mobx-react-lite";
import AddElement from "../../../assets/addElement.svg";
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';


export interface IAddButtonProps {
    onClickFunc: (e: any) => void;
    type: string;
}

const BtnAdd: FC<IAddButtonProps> = ({ onClickFunc, type }) => {

    if (type == 'file') {
        return (
            < div >
                < label className="input-file margin_btn" >
                    <input onChange={(e) => { onClickFunc(e) }} type="file" />
                    <span className="input-file-btn"><img src={AddElement} /></span>
                </label>
            </div >
        )
    } else {
        return (
            <div key={type} className="button_add margin_btn" onClick={onClickFunc}>
                <img src={AddElement} />
            </div>
        )
    }


}

export default observer(BtnAdd);



