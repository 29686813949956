import { ISustemPerson, IUser } from "../models/IUser";
import { makeAutoObservable } from "mobx";
import AuthService from "../services/AuthService";
import axios, { all, AxiosResponse } from "axios";
import { AuthResponse } from "../models/response/AuthResponse";
import { API_URL } from "../http";
import UserService from "../services/UserService";
import ProjectService from "../services/ProjectService";
import DashBoardService from "../services/DashBoardService";
import { IProject, IProjects } from "../models/IProject";
import { IDashBoard } from "../models/IDashBoard";
import { IColumnDisplay } from "../models/IColumnDisplay";
import { ITask, ITaskGeneralInfo } from "../models/ITask";
import TaskService from "../services/TaskService";
import FileService from "../services/FileService";
import { FileResponse } from "../models/response/FileResponce";
import { toJS } from "mobx";
import CommonService from "../services/CommonService";
import { ProjectForMenu } from "../models/response/LeftMenuResponse";
import {
    IGetTasksDisplayData,
    ITasksDisplay,
    ITasksDisplayFilters,
} from "../models/TaskModels";
import {
    IGetProjectHistoryDisplay,
    IProjectChangesDisplay,
    IProjectEditReq,
    IProjectGeneralInfo,
    IProjectProgressInfo,
    IProjectDeleteReq, IProjectCreateReq,
} from "../models/ProjectModels";
import { IGetTeamDisplay, ITeamDisplay } from "../models/TeamModels";
import {
    IFileFilterOptions,
    IFilesDisplay,
    IGetFilesDisplayData,
    IGetFilesTaskDisplayData,
} from "../models/FileModels";
import { IMoveTaskData } from "../models/IMoveTaskData";
import { IDeleteTasks } from "../models/IDeleteTasks";
import TeamService from "../services/TeamService";
import { IAddTeamDisplay, IDeleteTeamDisplay } from "../models/TeamModels";
import { ICreateTegTask } from "../models/ICreateTegTask";
import FilterService from "../services/FilterDataService";
import { IIParametrsFilter } from "../models/IFilterModel";
import { ITaskCreate } from "../models/ITaskCreate";
import { IBreadCrumb } from "../models/IBreadCrumb";
import { ITag } from "../models/ITag";
import { ITaskEdit } from "../models/ITaskEdit";
import { IChatMessage } from "../models/IChatMessage";
import { ITaskFormDisplay } from "../models/ITaskFormDisplay";
import { IColumnEdit } from "../models/IColumnEdit";
import ColumnService from "../services/ColumnService";

import { IMakeColumnProxy } from "../models/IMakeColumnProxy";
import { IMakeColumnCommon } from "../models/IMakeColumnCommon";
import { IDeleteColumn } from "../models/IDeleteColumn";
import { IUpdateTegTask } from "../models/IUpdateTegTask";
import { IUpdateTypeTask } from "../models/IUpdateTypeTask";
import { IDeleteBoard } from "../models/IDeleteBoard";
import { IAddColumn } from "../models/IAddColumn";
import { IMoveTasksData } from "../models/IMoveTasksData";
import { IBoardEdit } from "../models/IBoardEdit";
import { IMoveColumn } from "../models/IMoveColumn";
import { IRecoverPassword } from "../models/IRecoverPassword";
import { IFeedback } from "../models/IFeedback";
import { IGetTaskRequest } from "../models/IGetTaskRequest";
import {ITypeAndPriority} from "../models/ITypeAndPriority";
import {IEditElemSettings, IElemSettings} from "../models/IElemSettings";
import {IFile} from "../models/IFile";
export enum UserLocations {
    main,
    monitor,
    project,
    chat,
    info,
    board,
}

export default class Store {
    //User info
    user: IUser = {} as IUser;

    // Location data
    currentLocation: UserLocations = UserLocations.project;
    setCurrentLocation(val: UserLocations) {
        this.currentLocation = val;
    }

    currentProjectId: number = 0;

    setCurrentProjectId(val: number) {
        this.currentProjectId = val;
    }

    currentBoardId: number = 0;
    setCurrentBoard(val: number) {
        this.currentBoardId = val;
    }

    currentBoardData: IBreadCrumb = {} as IBreadCrumb;
    setCurrentBoardData(val: IBreadCrumb) {
        this.currentBoardData = val;
    }

    project: IProject = {} as IProject;
    projects: IProject[] = [];

    dashBoards: IDashBoard[] = [];

    columns: IColumnDisplay[] = [];

    tasks: ITask[] = [];

    isAuth: boolean = false;
    isLoading: boolean = false;
    isActivate: boolean = false;
    isDrag: boolean = true;

    //Настройки
    showProxyBoards: boolean = true;

    setDrag(drag: boolean) {
        console.log(drag)
        this.isDrag = drag;
    }

    setShowProxyBoards(showProxyBoards: boolean) {
        this.showProxyBoards = showProxyBoards;
    }

    isTaskUpdate: string = "";

    files: FileResponse[] = [];

    tree: ProjectForMenu = { nodes: [] };
    updateFile: boolean = false;

    setTaskUpdate(bool: string) {
        this.isTaskUpdate += bool;
        console.log(this.isTaskUpdate);
    }

    setUpdateFile(bool: boolean) {
        this.updateFile = bool;
    }

    constructor() {
        makeAutoObservable(this);
    }

    setActivate(bool: boolean) {
        this.isActivate = bool;
    }

    setAuth(bool: boolean) {
        this.isAuth = bool;
    }

    setUser(user: IUser) {
        this.user = user;
    }

    setProjects(projects: any) {
        this.projects = projects;
    }

    setProject(project: any) {
        this.project = project;
    }

    setDashBoard(dashBoards: any) {
        this.dashBoards = dashBoards;
    }

    setColumns(columns: any) {
        this.columns = columns;
    }

    setTasks(tasks: any) {
        this.tasks = tasks;
    }

    setLoading(bool: boolean) {
        this.isLoading = bool;
    }

    setFiles(files: any) {
        this.files = files;
    }

    setProgectsForMenu(tree: ProjectForMenu) {
        this.tree = tree;
    }

    personsWithRoles: ISustemPerson = {} as ISustemPerson;
    setPersonsWithRoles(personsWithRoles: any) {
        this.personsWithRoles = personsWithRoles;
    }

    dataForFilter: any;
    setDataForFilter(dataForFilter: any) {
        this.dataForFilter = dataForFilter;
    }

    async login(email: string, password: string) {
        try {
            const response = await AuthService.login(email, password);

            localStorage.setItem("token", response.data.accessToken);
            localStorage.setItem("email", response.data.userDto.email);

            this.setAuth(true);
            this.setActivate(response.data.userDto.isActivated);
            this.setUser(response.data.userDto);
        } catch (err: any) {
            return err.response?.data;
        }
    }

    async addTeg(
        taskId: number | undefined,
        projectId: number,
        name: string,
        color: string | undefined
    ): Promise<number | undefined> {
        try {
            let body: ICreateTegTask = {
                taskId: taskId,
                projectId: projectId,
                name: name,
                color: color,
            };
            const response = await TaskService.addTegTask(body);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getAllTags(
        taskId: number,
        projectId: number
    ): Promise<ITag[] | undefined> {
        try {
            const response = await TaskService.getTegs({
                taskId: taskId,
                projectId: projectId,
                userId: 0,
            });

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async updateTaskType(taskId: number, typeId: number): Promise<any> {
        try {
            let body: IUpdateTypeTask = {
                taskId: taskId,
                typeId: typeId,
            };
            const response = await TaskService.updateTaskType(body);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async updateTaskPriority(taskId: number, priorityId: number): Promise<any> {
        try {
            let body: IUpdateTegTask = {
                taskId: taskId,
                priorityId: priorityId,
            };
            const response = await TaskService.updateTaskPriority(body);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getTask(reqBody: IGetTaskRequest): Promise<ITaskGeneralInfo | undefined> {
        try {
            const response = await TaskService.getTask(reqBody);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getTypeAndPriority(): Promise<ITypeAndPriority | undefined> {
        try {
            const response = await TaskService.getTypeAndPriority();

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }


    async getChatMessage(taskId: number): Promise<IChatMessage | undefined> {
        try {
            const response = await TaskService.getChatMessage(taskId);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getTaskForm(taskId: number): Promise<ITaskFormDisplay | undefined> {
        try {
            const response = await TaskService.getTaskForm(taskId);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async addProject(
        groupId: number,
        photoId: number | undefined,
        startDate: Date,
        allottedTime: number,
        description?: string,
        shortDescription?: string,
        parentProjectId?: number
    ) {
        try {
            const response = await ProjectService.addProject(
                groupId,
                photoId,
                startDate,
                allottedTime,
                description,
                shortDescription,
                parentProjectId
            );
            this.setProject(response.data);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getAllProject() {
        try {
            const response = await ProjectService.getAllProject();

            this.setProjects(response.data);
            // console.log(this.project[0])
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getCurentProject(id: number) {
        try {
            const response = await ProjectService.getCurentProject(id);

            this.setProject(response.data);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async addDashBoard(name: string, projectId: number, groupId: number) {
        try {
            const response = await DashBoardService.addDashBoard(
                name,
                projectId,
                groupId
            );
            this.setDashBoard(response.data);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async editBoard(body: IBoardEdit) {
        try {
            await DashBoardService.editBoard(body);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getProjectDashBoards(id: number) {
        try {
            const response = await DashBoardService.getProjectDashBoards(id);

            this.setDashBoard(response.data);
            // this.setProject(response.data);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getProjectGroup(id: number) {
        try {
            const response = await DashBoardService.getProjectDashBoards(id);

            this.setDashBoard(response.data);
            // this.setProject(response.data);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getAllColumnsBoard(id: number) {
        try {
            const response = await DashBoardService.getAllColumnBoard(id);

            this.setColumns(response.data);
        } catch (err: any) {
            this.setColumns([]);
            return err.response?.data;
        }
    }

    async getAllTasksBoard(id: number) {
        try {
            const response = await DashBoardService.getAllTasksBoard(id);
            this.setTasks(response.data);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getProjectTasksDisplayData(
        body: IGetTasksDisplayData
    ): Promise<ITasksDisplay | null> {
        try {
            const response = await TaskService.getProjectTasksDisplayData(body);
            if (response.status === 200) {
                return response.data;
            } else {
                return null;
            }
        } catch (err: any) {
            console.log(err.response?.data?.message);
            return null;
        }
    }

    async deleteBoard(body: IDeleteBoard): Promise<void> {
        try {
            await DashBoardService.deleteBoard(body);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getProjectTasksFilters(
        projectId: number
    ): Promise<ITasksDisplayFilters | null> {
        try {
            const response = await TaskService.getProjectTasksFilters(
                projectId
            );

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
            return err.message;
        }
    }

    async createTask(body: ITaskCreate): Promise<ITaskFormDisplay | number> {
        try {
            const res = await TaskService.createTask(body);
            return res.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async addSystemSettings(newSettings: IEditElemSettings[]): Promise<any> {
        try {
            const res = await CommonService.addSystemSettings(newSettings);
            return res.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async editSystemSettings(newSettings: IEditElemSettings[]): Promise<any> {
        try {
            const res = await CommonService.editSystemSettings(newSettings);
            return res.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async getSystemCover(): Promise<any> {
        try {
            const res = await CommonService.getSystemCover();
            return res.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async getSystemLogo(): Promise<any> {
        try {
            const res = await CommonService.getSystemLogo();
            return res.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async getSystemSettings(): Promise<IElemSettings[]> {
        try {
            const res = await CommonService.getSystemSettings();
            return res.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async editTask(body: ITaskEdit): Promise<void> {
        try {
            await TaskService.editTask(body);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async addTagsInTasks(tags: ITag[], taskId: number): Promise<void> {
        try {
            const body = {
                tags: tags,
                taskId: taskId
            }

            await TaskService.addTagsInTasks(body);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async moveTask(body: IMoveTaskData): Promise<void> {
        try {
            await TaskService.moveTask(body);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async moveTasks(body: IMoveTasksData): Promise<void> {
        try {
            await TaskService.moveTasks(body);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async deleteTasks(body: IDeleteTasks): Promise<void> {
        try {
            await TaskService.deleteTasks(body);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async addColumn(body: IAddColumn): Promise<void> {
        try {
            await ColumnService.addColumn(body);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async editColumn(body: IColumnEdit): Promise<void> {
        try {
            await ColumnService.editColumn(body);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async moveColumn(body: IMoveColumn): Promise<void> {
        try {
            await ColumnService.moveColumn(body);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getAllTaskPerson(taskId: number): Promise<any> {
        try {
            console.log("ididididid", taskId);
            const response = await TaskService.getAllTaskPerson(taskId);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async makeColumnProxy(body: IMakeColumnProxy): Promise<void> {
        try {
            await ColumnService.makeColumnProxy(body);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async makeColumnCommon(body: IMakeColumnCommon): Promise<void> {
        try {
            await ColumnService.makeColumnCommon(body);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async deleteColumn(body: IDeleteColumn): Promise<void> {
        try {
            await ColumnService.deleteColumn(body);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }
    async getFilesTaskDisplayData(
        body: IGetFilesTaskDisplayData
    ): Promise<IFilesDisplay> {
        try {
            console.log("LOAD FILES 3");
            const response = await FileService.getFilesTaskDisplayData(body);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
            return err.message;
        }
    }

    async getFilesDisplayData(
        body: IGetFilesDisplayData
    ): Promise<IFilesDisplay | null> {
        try {
            console.log("LOAD FILES 2");
            const response = await FileService.getFilesDisplayData(body);

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
            return err.message;
        }
    }

    async getFileFilterOptions(): Promise<IFileFilterOptions | undefined> {
        try {
            const response = await FileService.getFileFilterOptions();
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
            return err.message;
        }
    }

    async getProjectProgressData(
        projectId: number
    ): Promise<IProjectProgressInfo | null> {
        try {
            const response = await ProjectService.getProjectProgressData(
                projectId
            );
            console.log(response);
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
            return err.message;
        }
    }

    async getProjectGeneralInfo(
        projectId: number
    ): Promise<IProjectGeneralInfo | null> {
        try {
            const response = await ProjectService.getProjectGeneralInfo(
                projectId
            );

            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
            return err.message;
        }
    }

    async getProjectHistoryDisplayData(
        body: IGetProjectHistoryDisplay
    ): Promise<IProjectChangesDisplay | null> {
        try {
            const response = await ProjectService.getProjectHistoryDisplayData(
                body
            );
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
            return err.message;
        }
    }

    async getProjectTeamDisplayData(
        body: IGetTeamDisplay
    ): Promise<ITeamDisplay | null> {
        try {
            const response = await ProjectService.getProjectTeamDisplayData(
                body
            );
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
            return null;
        }
    }

    async registration(
        email: string,
        password: string,
        name: string,
        surname: string,
        middlename: string
    ) {
        try {
            await AuthService.registration(
                email,
                password,
                name,
                surname,
                middlename
            );
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async chekActivate(email: string) {
        try {
            const response = await AuthService.chekActivate(email);
            console.log(response.data.userDto.isActivated);
            if (response.data.userDto.isActivated == true) {
                this.setActivate(true);
                this.setAuth(true);
            }
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async getUser(email: string) {
        try {
            const response = await AuthService.fetchCurentUser(email);

            this.setUser(response.data.userDto);
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async logout() {
        try {
            const response = await AuthService.logout();

            localStorage.removeItem("token");
            localStorage.removeItem("email");
            this.setAuth(false);
            console.log("logout", this.isAuth);
            this.setUser({} as IUser);
            console.log("gg");

            return true;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async chekAuth() {
        this.setLoading(true);
        try {
            const response = await axios.get<AuthResponse>(
                `${API_URL}/refresh`,
                { withCredentials: true }
            );

            localStorage.setItem("token", response.data.accessToken);
            this.setAuth(true);
            this.setActivate(response.data.userDto.isActivated);
            this.setUser(response.data.userDto);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        } finally {
            this.setLoading(false);
        }
    }

    async requestPasswordRecover(email: string) {
        try {
            await AuthService.requestPasswordRecover(email);
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async recoverPassword(body: IRecoverPassword) {
        try {
            await AuthService.recoverPassword(body);
        } catch (err: any) {
            return err.response.data;
        }
    }

    async addRole(userId: number, roleId: number) {
        try {
            const response = await UserService.addRole(userId, roleId);
            return response.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async saveEditMessage(message: string, messageId: number) {
        try {
            const messageInt: { message: string; messageId: number } = {
                message: message,
                messageId: messageId,
            };
            const response = await TaskService.saveEditMessage(messageInt);
            return response.data;
        } catch (err) { }
    }

    async deleteMessage(idMessage: number) {
        try {
            const response = await TaskService.deleteMessage(idMessage);
            return response.data;
        } catch (err) { }
    }

    async sendMessage(message: string, taskId: number) {
        try {
            const messageInt: { message: string; taskId: number } = {
                message: message,
                taskId: taskId,
            };
            const response = await TaskService.sendMessage(messageInt);
            return response.data;
        } catch (err) { }
    }

    async addFileChat(file: any, taskId: number, useId: number) {
        try {
            const response = await FileService.addFileChat(file, taskId, useId);
            return response.data.url;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async addSystemFile(file: any): Promise<{url: string, fileId: number}> {
        try {
            const response = await FileService.addSystemFile(file);
            return response.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async addFile(file: any, projectId: number, useId: number) {
        try {
            const response = await FileService.addFile(file, projectId, useId);
            return response.data;
        } catch (err: any) {
            return err.response?.status;
        }
    }

    async getFiles(id: number) {
        try {
            const response = await FileService.getProjectFiles(id);
            console.log("files--", response.data);

            response.data && this.setFiles(response.data);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async downloadFile(id: string) {
        try {
            const response = await FileService.downloadFile(id);
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async deleteFiles(ids: number[]) {
        try {
            const response = await FileService.deleteFile(ids);
            console.log("files--", response.data);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async getProjectsForLeftMenu() {
        try {
            const response = await CommonService.getProjects();
            if (response.data) this.tree = response.data;
            // this.setProgectsForMenu(response.data);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async addFeedback(reqBody: IFeedback) {
        try {
            await CommonService.addFeedback(reqBody);
        } catch (err: any) {
            return err.response?.data;
        }
    }

    async getSystemPersonWithRoles() {
        try {
            const response = await UserService.getSystemPersonWithRoles();
            this.setPersonsWithRoles(response.data);
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async addTeamMembers(body: IAddTeamDisplay) {
        try {
            const response = await TeamService.addTeamMembers(body);
            return null;
        } catch (err: any) {
            console.log(err.response?.data?.message);
            return err;
        }
    }

    async deleteTeamMembers(body: IDeleteTeamDisplay) {
        try {
            const response = await TeamService.deleteTeamMembers(body);
            return null;
        } catch (err: any) {
            console.log(err.response?.data?.message);
            return err.response?.data?.message;
        }
    }

    async getDataForFilter(body: IIParametrsFilter) {
        try {
            const response = await FilterService.getFilters(body);
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async createProject(body: IProjectCreateReq) {
        try {
            const response = await ProjectService.createProject(body);
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }

    async editProject(body: IProjectEditReq) {
        try {
            const response = await ProjectService.editProject(body);
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }
    async deleteProject(body: IProjectDeleteReq) {
        try {
            const response = await ProjectService.deleteProject(body);
            return response.data;
        } catch (err: any) {
            console.log(err.response?.data?.message);
        }
    }
}
