
import React, { useContext, useEffect } from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import '../HeadNamePage/HeadNamePage.css'
import { AppContext } from '../../../App';

export default function HeadNamePage() {
    const {breadCrumb} = useContext(AppContext)

    return (
        <div className='headName' >
            <BreadCrumb model={breadCrumb} />
        </div>

    )
}
