
import React, { FC, useState } from "react";
import { ListBox, ListBoxChangeEvent } from 'primereact/listbox';
import { observer } from "mobx-react-lite";
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';

interface Item {
    name: string;
    code: string;
}

interface IChooseItem {
    selectedItem: Item,
    setSelectedItem:(e: any) => void;
    placeholderName: string,
    data: any
}
const ChooseItem: FC<IChooseItem> = ({selectedItem, setSelectedItem, placeholderName, data}) => {

    const countries: Item[] = [
        { name: 'Australia', code: 'AU' },
        { name: 'Brazil', code: 'BR' },
        { name: 'China', code: 'CN' },
        { name: 'Egypt', code: 'EG' },
        { name: 'France', code: 'FR' },
        { name: 'Germany', code: 'DE' },
        { name: 'India', code: 'IN' },
        { name: 'Japan', code: 'JP' },
        { name: 'Spain', code: 'ES' },
        { name: 'United States', code: 'US' }
    ];

    const selectedCountryTemplate = (option: Item, props: any) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option: Item) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{option.name}</div>
            </div>
        );
    };

    return (
        <div className="card flex justify-content-center">
            <Dropdown value={selectedItem} onChange={(e: DropdownChangeEvent) => setSelectedItem(e.value)} options={data} optionLabel="name" placeholder={placeholderName} 
                filter   className="w-full md:w-14rem" />
        </div>    
    )
}
export default observer(ChooseItem);