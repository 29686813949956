import { FC } from 'react'
import "./ConfirmationWindow.css"
import "../../styles/modalWindow.css"
interface IConfirmationWindow {
  headerText: string;
  bodyText: string;
  confirmText: string;
  cancelText: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationWindow: FC<IConfirmationWindow> = ({ headerText, bodyText, confirmText, cancelText, onConfirm, onCancel }) => {
  return (
    <div className='modal-window-overlay'>
      <div className='modal-window-container'>
        <div>
          <h1 className='p_black_black'>
            {headerText}
          </h1>
          <p className='p_black_black p-18'>{bodyText}</p>
        </div>
        <div className='modal-window-btns'>
          <button className='confirm_btn  p_blue p_14 button_reset_style' onClick={onConfirm}>{confirmText}</button>
          <button className='cancel_btn p_white p_14 modal-window-margin-btn button_reset_style' onClick={onCancel}>{cancelText}</button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationWindow