import $api from "../http";
import { AxiosResponse } from "axios";

import {ProjectForMenu} from "../models/response/LeftMenuResponse";
import { TreeNode } from "../models/LeftMenuModels";
import { IFeedback } from "../models/IFeedback";
import {ITaskCreate} from "../models/ITaskCreate";
import {ITaskFormDisplay} from "../models/ITaskFormDisplay";
import {IEditElemSettings, IElemSettings} from "../models/IElemSettings";
import {IFile} from "../models/IFile";

export default class CommonService {
    static async getSystemCover(): Promise<AxiosResponse<any>> {
        return $api.get<any>(`System/settings/getSystemCover`);
    }

    static async getSystemLogo(): Promise<AxiosResponse<any>> {
        return $api.get<any>(`System/settings/getSystemLogo`);
    }

    static async getProjects(): Promise<AxiosResponse<ProjectForMenu>> {
        return $api.get<ProjectForMenu>(`sideBar`); 
    }

    static async addFeedback(reqBody: IFeedback): Promise<void> {
        return $api.post(`addFeedback`, reqBody); 
    }

    static async addSystemSettings(reqBody: IEditElemSettings[]): Promise<AxiosResponse<any>> {
        return $api.post(`System/addSettings`, reqBody);
    }

    static async editSystemSettings(reqBody: IEditElemSettings[]): Promise<AxiosResponse<any>> {
        return $api.post(`System/editSettings`, reqBody);
    }

    static async getSystemSettings(): Promise<AxiosResponse<IElemSettings[]>> {
        return $api.get("System/settings");
    }
}