import $api from "../http";
import { AxiosResponse } from "axios";
import {FileChatResponse, FileResponse} from "../models/response/FileResponce";
import {
    IFileFilterOptions,
    IFilesDisplay,
    IGetFilesDisplayData,
    IFilesDelete,
    IFilesDownload,
    IGetFilesTaskDisplayData
} from "../models/FileModels";

export default class FileService {

    static async addSystemFile(file: any): Promise<AxiosResponse<{url: string, fileId: number}>> {
        const formData = new FormData();
        formData.append('file', file, encodeURI( file.name));

        return $api.post<{url: string, fileId: number}>('System/addSystemFile', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static async addFile(file: any, projectId: number, userId: number): Promise<AxiosResponse<FileResponse>> {
        const formData = new FormData();
        formData.append('file', file, encodeURI( file.name)); 
        formData.append('projectId', `${projectId}`);
        formData.append('userId', `${userId}`);
    
        return $api.post<FileResponse>('Project/addFile', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static async addFileChat(file: any, taskId: number, userId: number): Promise<AxiosResponse<FileChatResponse>> {
        const formData = new FormData();
        formData.append('file', file, encodeURI( file.name)); 
        formData.append('taskId', `${taskId}`);
        formData.append('userId', `${userId}`);

        return $api.post<FileChatResponse>('Project/addFileChat', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    static async downloadFile(fileId:string): Promise<AxiosResponse<IFilesDownload>> {
        return $api.get<IFilesDownload>(`/Project/getFile?fileId=${fileId}`);
    }

    static async getProjectFiles(projectId: number): Promise<AxiosResponse<FileResponse>> {
        return $api.get<FileResponse>(`/Project/getFile?projectId=${projectId}`);
    }

    static async getFilesDisplayData(body: IGetFilesDisplayData): Promise<AxiosResponse<IFilesDisplay>> {
        return $api.post<IFilesDisplay>(`/Project/getFiles`, body);
    }

    static async getFilesTaskDisplayData(body: IGetFilesTaskDisplayData): Promise<AxiosResponse<IFilesDisplay>> {
        return $api.post<IFilesDisplay>(`/Project/Board/Task/getTaskFiles`, body);
    }

    static async getFileFilterOptions(): Promise<AxiosResponse<IFileFilterOptions>> {
        return $api.get<IFileFilterOptions>(`/Project/getFilesFilters`);
    }

    static async deleteFile(filesIds: number[]): Promise<AxiosResponse<IFilesDelete>> {
        return $api.post<IFilesDelete>('Project/deleteFiles', filesIds);
    }

}