import React, { FC, useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import DocumentCopy from '../../../assets/document-copy.svg';
import { CSSTransition } from 'react-transition-group';

export interface ICopyButtonProps {
    textDivId: string;
}

const BtnCopyText: FC<ICopyButtonProps> = ({ textDivId }) => {
    const [show, setShow] = useState(false)
    function onClick() {
        const text = document.getElementById(textDivId)?.innerText;
        if (!text) { return }
        navigator.clipboard.writeText(text);
        setShow(true)
        setTimeout(function () {
            setShow(false);
        }, 1000);
    }

    return (
        <div className='button_copy'>
            <div className="button-base" onClick={onClick}>
                <img src={DocumentCopy} />
            </div>

            {show ? <p className='p_blue show'>Скопировано в буфер обмена</p> 
            :<p className='p_blue hide'>Скопировано в буфер обмена</p> }
        </div>

    )
}

export default observer(BtnCopyText);