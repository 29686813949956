import React, { useContext, useState } from "react";
import CancelButton from "../../buttons/CancelButton/CancelButton";
import Search from "../../Shared/Search/Search";
import "./BoardToolbar.css";
import BtnDelete from "../../buttons/btnDelete.component/BtnDelete";
import BtnMove from "../../buttons/MoveButton/MoveButton";
import BtnSettings from "../../buttons/SettingsButton/SettingsButton";
import ActionModeButton from "../../buttons/BoardActionModeButton/ActionModeButton";
import BoardFastFilters from "../BoardFastFilters/BoardFastFilters";
import { BoardContext } from "../../../pages/Board/Board";
import BtnFilter from "../../buttons/btnFilter.component/BtnFilter";
import BtnCancel from "../../../component/buttons/CancelButton/CancelButton"
import { setConstantValue } from "typescript";
import { IFilterData } from "../../../models/TaskModels";

export interface IToolbarProps {
    onMassActionsFunc: () => void;
    selectedCount: number;
}

const BoardToolbar: React.FC<IToolbarProps> = ({
    onMassActionsFunc,
    selectedCount,
}) => {
    const [showMoveDropdown, setShowMoveDropdown] = useState<boolean>();

    const {
        taskNameSearch,
        setTaskNameSearch,
        actionMode,
        cancelActionMode,
        handleDeleteTasks,
        handleMoveTasks,
        selectedTasks,
        columns,
        openMenu,
        boardFilters,
        setBoardFilters,
        checkedBoardFilters,
        setCheckedBoardFilters,
        datesFilter,
        setDatesFilter,
        usersFilter,
        setUsersFilter,
        filterCounter,
        setFilterCounter,
        resetFilter,
        selectedFastFilters,
        changeFastFilters
    } = useContext(BoardContext);

    const handleDeleteSubmit = () => {
        handleDeleteTasks(selectedTasks);
    };

    const handleSettingsClick = () => {};

    const handleActionModeClick = () => {
        onMassActionsFunc();
    };

    const handleColumnClick = (e: any, columnId: number) => {
        handleMoveTasks(columnId);
        setShowMoveDropdown(false);
        e.stopPropagation();
    };

    function onCancelClick() {
        resetFilter();
    }

    return (
        <div className="board-toolbar">
            <div className="toolbar-left">
                <ActionModeButton onClickFunc={handleActionModeClick} isActive={actionMode} selectedCount={1}/>
                {actionMode ? 
                    <span className="toolbar__point--task__counter">
                        {selectedCount}
                    </span> 
                : 
                    <span className="toolbar__point--task__counter">
                    </span>
                }
                <BoardFastFilters />
                {actionMode ? (
                    <CancelButton onClickFunc={cancelActionMode} />
                ) : null}
            </div>
            <div className="toolbar-right" style={{ alignSelf: "end" }}>
                {actionMode && selectedCount > 0 && (
                    <div className="mass-actions">
                        <BtnMove
                            onClickFunc={() =>
                                setShowMoveDropdown(!showMoveDropdown)
                            }
                        />
                        {showMoveDropdown ? (
                            <div className="move-task-popup-container">
                                <div
                                    className="move-task-popup-overlay"
                                    onClick={() => {
                                        setShowMoveDropdown(false);
                                    }}
                                ></div>
                                <div className="move-task-popup">
                                    <p className="move-task-popup-header">Переместить в колонку</p>
                                    <div className="move-task-popup-column-container">
                                        {columns.map((c) => (
                                            <p
                                                className="move-task-popup-column-text"
                                                onClick={(e) =>
                                                handleColumnClick(e, c.id)
                                            }>
                                                {c.name}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <BtnDelete onClickFunc={handleDeleteSubmit} />
                    </div>
                )}
                <Search
                    defaultValue={taskNameSearch}
                    onSubmitFunc={() => {}}
                    changeSearchValue={setTaskNameSearch}
                />
                <BtnFilter
                    counter={filterCounter}
                    setCounter={setFilterCounter}
                    filterData={boardFilters}
                    setFilterData={setBoardFilters}
                    applayFilters={async () => {}}
                    checkedFilters={checkedBoardFilters}
                    setCheckedFilters={setCheckedBoardFilters}
                    dates={datesFilter}
                    setDates={setDatesFilter}
                    selectedUsers={usersFilter}
                    setSelectedUsers={setUsersFilter}
                    showApplyButton={false}
                />
                {(filterCounter !== 0 || taskNameSearch) && <BtnCancel onClickFunc={onCancelClick} />}
                <BtnSettings onClickFunc={openMenu} />
            </div>
        </div>
    );
};

export default BoardToolbar;
