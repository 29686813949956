import React, {createElement, FC, useContext, useEffect, useMemo, useRef, useState} from 'react';
import "./InputChat.css"
import SimpleMDEditor from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import { Context } from '../../..';
import { useParams } from 'react-router-dom';
import UserPicture from '../../../assets/user.svg'
import {
    IFilesDisplay,
    IFileTaskFilters,
    IGetFilesTaskDisplayData
} from "../../../models/FileModels";
import MarkdownPreview from '../ChatMarkdown/ChatMarkdown';
import { IChatMessage } from '../../../models/IChatMessage';
import { format } from 'date-fns'

type ToolbarButton =
    'bold'
    | 'italic'
    | 'quote'
    | 'unordered-list'
    | 'ordered-list'
    | 'link'
    | 'image'
    | 'strikethrough'
    | 'code'
    | 'table'
    | 'redo'
    | 'heading'
    | 'undo'
    | 'heading-bigger'
    | 'heading-smaller'
    | 'heading-1'
    | 'heading-2'
    | 'heading-3'
    | 'clean-block'
    | 'horizontal-rule'
    | 'preview'
    | 'side-by-side'
    | 'fullscreen'
    | 'guide';

interface Options {
    showIcons?: ReadonlyArray<ToolbarButton>;
}

type ImageUploadType = {
    (image: File,
     onSuccess: (url: string) => void,
     onError: (errorMessage: string) => void) : void
}

const InputChat: FC = () => {
    const { store } = useContext(Context);
    const [value, setValue] = useState("");
    const [saveValue, setSaveValue] = useState("");
    const [resultValue, setResultValue] = useState("");
    // const [fileMap, setFileMap] = useState<{ [fileId: string]: string | undefined }>({});
    const [fileMap, setFileMap] = useState<{data: {id: string, url: string}[]}>({ data: [] });
    const [edit, setEdit] = useState(false);
    const [idEditMessage, setidEditMessage] = useState(-1);
    const [chatMessage, setChatMessage] = useState<IChatMessage>();
    const [url, setUrl] = useState("");
    const [files, setFiles] = useState<IFilesDisplay | undefined>(undefined);
    const { id } = useParams();
    const containerRef = useRef<HTMLDivElement>(null)

    const loadFiles = async (filters: IFileTaskFilters) => {
        let req: IGetFilesTaskDisplayData = {
            filters: filters
        }
        const res = await store.getFilesTaskDisplayData(req) as IFilesDisplay;
        setFiles(res);
    };

    function chekDate(): boolean {
        const today = new Date();
        const lastDayInChat = chatMessage?.message[chatMessage?.message?.length - 1]?.
            messages[chatMessage?.message[chatMessage?.message?.length - 1]?.messages.length -1];
        const lastDateInChat = new Date(String(lastDayInChat?.createdAt));
        console.log(lastDateInChat.getDate() === today.getDate() &&
            lastDateInChat.getMonth() === today.getMonth() &&
            lastDateInChat.getFullYear() === today.getFullYear())

        if (
            lastDateInChat.getDate() === today.getDate() &&
            lastDateInChat.getMonth() === today.getMonth() &&
            lastDateInChat.getFullYear() === today.getFullYear()
        ) {
            return false;
        } else {
            return true;
        }
    }

    function getCurentDate(messageDateCur: string) {
        const messageDate = new Date(messageDateCur);
        const today = new Date();
        const yesterday = new Date(today);
        const dayBeforeYesterday = new Date(today);

        yesterday.setDate(today.getDate() - 1);
        dayBeforeYesterday.setDate(today.getDate() - 2);

        if (
            messageDate.getDate() === today.getDate() &&
            messageDate.getMonth() === today.getMonth() &&
            messageDate.getFullYear() === today.getFullYear()
        ) {
            return "Сегодня";
        } else if (
            messageDate.getDate() === yesterday.getDate() &&
            messageDate.getMonth() === yesterday.getMonth() &&
            messageDate.getFullYear() === yesterday.getFullYear()
        ) {
            return "Вчера";
        } else if (
            messageDate.getDate() === dayBeforeYesterday.getDate() &&
            messageDate.getMonth() === dayBeforeYesterday.getMonth() &&
            messageDate.getFullYear() === dayBeforeYesterday.getFullYear()
        ) {
            return "Позавчера";
        } else {
            return `${messageDate.getDate().toString().padStart(2, '0')}.${(messageDate.getMonth() + 1).toString().padStart(2, '0')}.${messageDate.getFullYear()}`;
        }
    }

    const generateFileId = (fileType: string) => {
        const id = Date.now().toString(); //.slice(0,5);
        return `${fileType}${id}`;
    };

    useEffect(() => {
        console.log(fileMap)
        console.log("value", value)
        replaceHtml(value);
    }, [fileMap, value, id]);

    //Обработка файлов
    const imageUpload = async (file: any, onSuccess: any, onError: any) => {
        try {
            const url = await store.addFileChat(file, Number(id), 1);
            store.setUpdateFile(true);
            let fileType = 'F';
            if (file.type.includes("image")) {
                fileType = 'G';
            }

            const fileId = generateFileId(fileType);
            const newFileMap: { id: string, url: string } = {
                id: fileId,
                url: url
            };

            setFileMap(prevFileMap => ({
                data: [...prevFileMap.data, newFileMap]
            }));
            setValue(oldValue => oldValue + ` [${fileId}]`);
        } catch (error) {
            onError("Ошибка загрузки изображения");
        }
    };

    const replaceText = (valueRep: string) => {
        const saveValueRep = valueRep;
        console.log(value)
        setFileMap({data: []});
        console.log("replaceText", fileMap);
        const regex = /<[^>]+>/g;
        let index = -1;
        const newFileMap: { id: string, url: string }[] = [];
        valueRep = valueRep.replaceAll(regex, (match, type, id) => {
            index += 1;
            console.log(match,"||||", type, id)
            const fileId = generateFileId("O");
            const regex = /http[s]?:\/\/[^\s"']+/g;
            const url = String(regex.exec(match));
            fileMap.data.push({
                id: fileId,
                url: url
            });
            console.log(match.includes('img'))
            if (match.includes('img')) {
                return `[${fileId}]`;
            } else if (match.includes('a')) {
                return `[${fileId}]`;
            }
            return match;
        });
        setFileMap(prevFileMap => ({
            ...prevFileMap, ...newFileMap
        }));
        console.log(fileMap)
        console.log("saveValueRep", saveValueRep)
        setResultValue(saveValueRep);

        return valueRep
    };

    const replaceHtml = (valueRep: string) => {
        setValue(valueRep);
        console.log("replaceHtml", fileMap);
        const regex = /(\[[GF]\d+\])/g;
        let index = -1;
        valueRep = valueRep.replaceAll(regex, (match, type, id) => {
            index += 1;
            if (type.includes('G')) {
                return `<img src="${fileMap?.data[index]?.url}" />`;
            } else if (type.includes('F')) {
                return `<a href="${fileMap?.data[index]?.url}">${match}</a>`;
            }
            return match;
        });
        setResultValue(valueRep);
    };

    const onChange = (newValue: string) => {
        setValue(newValue);
        replaceBlocks(value);
        // setResultValue(newValue);
    };

    function replaceBlocks(text: string) {
        const fileIds = text.match(/\[[GF](\d+)\]/g) || [];
        const foundFileIds = new Set(fileIds.map(id => id.slice(1, -1)));
        let filteredData = fileMap.data.filter(item => foundFileIds.has(item.id));
        if (filteredData) {
            setFileMap({data: filteredData});
        }
    }

    const closeEditMessage = () => {
        setValue("");
        setEdit(false);
        setidEditMessage(-1);
    }

    const saveEditMessage = () => {
        (async () => {
            await store.saveEditMessage(resultValue, idEditMessage);
            setValue("");
            await loadChatMessage();
            setEdit(false);
            setidEditMessage(-1);
        })()
    }

    const saveMessage = () => {
        (async () => {
            await store.sendMessage(resultValue, Number(id));
            setValue("");
            await loadChatMessage();
        })();
    };

    function formatDate(message: string) {
        const date = new Date(message);
        return format(date, 'MMM dd, yyyy');
    }

    function deleteMessage(idMessage: number) {
        (async () => {
            await store.deleteMessage(idMessage);
            await loadChatMessage();
        })()
    }

    function editMessage(idMessage: number, date: string) {
        const dateForm = new Date(date);
        const curentDateString =  format(date, 'yyyy-MM-dd');
        const message = chatMessage?.message.find((xx) => xx.date == curentDateString)!.messages.find((yy) => yy.id == idMessage);
        setEdit(true);
        setidEditMessage(idMessage);
        setValue(message!.text);
        // const repValue = replaceText(message!.text);
        // console.log("valueRep", repValue)
        // setValue(repValue);

        // console.log("valueRep", resultValue)
    }

    const newOptions = useMemo(() => {
        return {
            spellChecker: false,
            showIcons: ['code'],
            uploadImage: true,
            imageUploadFunction: imageUpload,
            placeholder: "Введите текст...",
        } as Options;
    }, []);

    const loadChatMessage = async () => {
        let res = await store.getChatMessage(Number(id))
        setChatMessage(res!);
    }

    useEffect(() => {
        (async () => {
            try {
                await loadChatMessage()
                console.log("chatMessage", chatMessage)
                console.log(store.user.surname + store.user.name)
            } catch (error) {
                console.log(error)
            }
        })();
    }, [id]);

    return (
        <div  className="input--chat__block">
            <div className="input--chat__block--messages">
                {chatMessage?.message?.map((message, index) => (
                    <div>
                        <div key={index + "date"} className="input--chat__block--messages__date">
                            <div className="input--chat__block--messages__date--line"></div>
                            <div className="input--chat__block--messages__date--num">
                                {getCurentDate(message?.date)}
                            </div>
                            <div className="input--chat__block--messages__date--line"></div>
                        </div>
                        {message?.messages?.map((curentMessages) => (
                            <div className={
                                    store.user.surname + " " + store.user.name === curentMessages.createdBy
                                        ? "input--chat__block--messages__message--block__right"
                                        : "input--chat__block--messages__message--block__left"
                                }
                            >
                                <div className={
                                    store.user.surname + " " + store.user.name === curentMessages.createdBy
                                        ? "input--chat__block--messages__message--block__flex--right"
                                        : "input--chat__block--messages__message--block__flex--left"
                                    }
                                     style={edit && curentMessages.id == idEditMessage ? { backgroundColor: 'lightblue' } : {}}
                                >
                                    <div className="input--chat__block--messages__message--img">
                                        <img src={UserPicture}/>
                                    </div>
                                    <div key={curentMessages.id} className="input--chat__block--messages__message">
                                        <div className="input--chat__block--messages__message--all">
                                            <div className="input--chat__block--messages__message--autor">
                                                {curentMessages.createdBy}
                                            </div>
                                            <div className="input--chat__block--messages__message--role">
                                                {curentMessages.role}
                                            </div>
                                            <div className="input--chat__block--messages__message--date">
                                                {formatDate(curentMessages.createdAt)}
                                            </div>
                                            {
                                                curentMessages.updatedAt == curentMessages.createdAt ? "" :
                                                    <a className="input--chat__block--messages__message--update">Изменено</a>
                                            }
                                        </div>
                                        <div className="input--chat__block--messages__message--text">
                                            <MarkdownPreview content={edit && curentMessages.id == idEditMessage ? resultValue : curentMessages.text}/>
                                        </div>
                                        {store.user.surname + " " + store.user.name === curentMessages.createdBy ?
                                            <div className="input--chat__block--messages__message--buttons">
                                                <button onClick={() => editMessage(curentMessages.id, curentMessages.createdAt)}>Редактировать</button>
                                                <button onClick={() => deleteMessage(curentMessages.id)}>Удалить</button>
                                            </div>
                                        : ""}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            {value && !edit ?
                <div>
                    {chekDate() ?
                        <div className="input--chat__block--messages__date">
                            <div className="input--chat__block--messages__date--line"></div>
                            <div className="input--chat__block--messages__date--num">
                                Сегодня
                            </div>
                            <div className="input--chat__block--messages__date--line"></div>
                        </div>
                    : ""}
                    <div className="input--chat__block--messages__message--block__right">
                        <div className="input--chat__block--messages__message--block__flex--right">
                            <div className="input--chat__block--messages__message--img">
                                <img src={UserPicture}/>
                            </div>
                            <div className="input--chat__block--messages__message">
                                <div className="input--chat__block--messages__message--all">
                                    <div className="input--chat__block--messages__message--autor">
                                        {store.user.surname + " " + store.user.name}
                                    </div>
                                    <div className="input--chat__block--messages__message--date">
                                        {formatDate(String(new Date()))}
                                    </div>
                                </div>
                                <div className="input--chat__block--messages__message--text">
                                    <MarkdownPreview content={resultValue}/>
                                    {/*<MarkdownPreview content={value}/>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : ""}
            <div className="input--input__block">
                <div className="input--input__block--buttons">
                    <SimpleMDEditor
                        id="editor"
                        value={value}
                        onChange={onChange}
                        options={newOptions}
                    />
                    <div className="input--input__block--buttons__send">
                        <div className="input--input__block--buttons__send--div">
                            {edit ?
                                <div>
                                    <button className="input--input__block--buttons__send--div__cansel" onClick={closeEditMessage}>
                                        Отмена
                                    </button>
                                    <button className="input--input__block--buttons__send--div__approve" onClick={saveEditMessage}>
                                        Сохранить
                                    </button>
                                </div>
                            :
                                <button className="input--input__block--buttons__send--div__approve" onClick={saveMessage}>
                                    Отправить
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InputChat;