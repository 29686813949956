import React, {useContext, useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {observer} from "mobx-react-lite";
import '../MisceleniousStyles.css'
import './TasksTagDisplay.css'
import BtnAdd from "../../buttons/btnAdd.component/BtnAdd";
import ColorPicker from "../ColorPicker";
import TagSelector from "../TagSelectionPopup/TagSelectionPopup";
import {Context} from "../../../index";
import { ITag } from '../../../models/ITaskFormDisplay';
import ExitCross from "../../../assets/cancelGreey.svg"




const tagsFolded: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    overflowX: "hidden"
}
const tagsUnfolded: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    flexWrap: "wrap"
}
const buttonFolded: React.CSSProperties = {
    backgroundColor: "lightgray",
    color: "#fff",
    padding: "3px",
    height: "var(--button-width-size-36-px)",
    width: "var(--button-width-size-36-px)",

    position: "absolute",
    right: "0",
    zIndex: "1000"
}
const buttonUnfolded: React.CSSProperties = {
    backgroundColor: "lightgray",
    color: "#fff",
    padding: "3px"
}

interface ITasksTagDisplayProps {
    tags: ITag[];
    projectId: number;
    taskId: number | undefined;
    addTagsFunc: (tags: any[]) => void;
    removeTag: (tag: ITag) => void;
}

const TasksTagDisplay: React.FC<ITasksTagDisplayProps> = ({tags, projectId,taskId , addTagsFunc, removeTag}) => {
    const { store } = useContext(Context);
    const [showDeleteIcon, setShowDeleteIcon] = useState(false);
    const [showTagPopup, setShowTagPopup] = React.useState(false);
    const [showAllTags, setShowAllTags] = React.useState(false);

    const [tagVariants, setTagVariants] = useState<any[]>([]);

    const [tagName, setTagName] = React.useState<string | null>(null);


    function onClosePopup(){
        setShowTagPopup(false)
    }

    async function onAddButton(){
        const tegsRes = await store.getAllTags(1, 1) //FIXME
        if(tegsRes){setTagVariants(tegsRes as any)}
        setShowTagPopup(true);
    }

    function onTagNameChange(e: any){
        setTagName(e.target.value)
    }

    useEffect(() => {

    }, [tagVariants]);

    function onAddTagsAccept(_tags: any[]){
        addTagsFunc(_tags)
        _tags.length == 0 ? tags = [] : tags.filter(xx => _tags.includes(xx.id))
    }

    useEffect(() => {

    }, [tags]);

    function handleRemoveTag(tag: ITag){
        removeTag(tag);
    }

    function changeTagsFold(){
        setShowAllTags(!showAllTags)
    }

    return (
        <div style={{position: "relative"}}>
            <div style={showAllTags ? tagsUnfolded : tagsFolded} className='tag-add-popup-tegs__block'>
                <div className='tag-add-popup-button--block'>
                    <BtnAdd type="tag-add-popup-button" onClickFunc={onAddButton}/>
                </div>
                {tags.length > 3 && !showAllTags ? (
                    <div className='tegs_popup-items'>
                        {tags.slice(0, 3).map((item, index) => (
                            <div 
                                key={index}
                                className="tag-display-field tag-display-field__size"
                                style={{backgroundColor: `${item.color!}`}}
                            >
                                <div style={{color: "rgba(0, 0, 0, 0.7)"}}>
                                    {item.name}
                                </div>
                                {/*{showDeleteIcon && (*/}
                                    <div className='tag-add-popup__del--teg' id={String(item.id)} onClick={() => handleRemoveTag(item)}>
                                        <img src={ExitCross} />
                                    </div>
                                {/*)}*/}
                            </div>
                        ))}
                        <div className='tag-add-popup__show--all' onClick={changeTagsFold}>
                            {"+" + (tags.length - 3)}
                        </div>
                    </div>
                ) : (
                    <div className='tegs_popup-items'>
                        {tags.map((item, index) => (
                        <div 
                        key={index} 
                        className="tag-display-field tag-display-field__size"
                        style={{backgroundColor: `${item.color!}`}}
                      >
                        <div style={{color: "rgba(0, 0, 0, 0.7)"}}>
                            {item.name}
                        </div>
                        {/*{showDeleteIcon && (*/}
                          <div className='tag-add-popup__del--teg' id={String(item.id)} onClick={() => handleRemoveTag(item)}>
                            <img src={ExitCross} />
                          </div>
                        {/*)}*/}
                      </div>
                        ))}
                        {showAllTags ? (
                            <div className='tag-add-popup__hide__all' onClick={changeTagsFold} style={showAllTags ? buttonUnfolded : buttonFolded}>
                                <img src={ExitCross}/>
                            </div>
                        ) : (<></>)} 
                    </div>
                )}
            </div>
            {showTagPopup ? (
                <>
                    <div style={{opacity: "0.8"}} className='full-screen-overlay'
                         onClick={onClosePopup}></div>
                    <div className='task__teg--display__popup--add__teg'>
                        <div className='task__teg--display__popup--add__teg--header'>
                            <div className='task__teg--display__popup--add__teg--name'>
                                Добавление тегов
                            </div>
                            <div className='task__teg--display__popup--add__teg--cross' onClick={onClosePopup}>
                                <img src={ExitCross}/>
                            </div>
                        </div>
                        <div className='task__teg--display__popup--add__teg--name__TagSelector'>
                            <TagSelector tags={tags}
                                         projectId={projectId}
                                         taskId={taskId}
                                         addTagsFunc={onAddTagsAccept}
                                         closeFunc={onClosePopup}/>
                        </div>
                        <div className='tegs__color--popup__button--add__save--block'>
                            <div className='tegs__color--popup__button--add__teg'
                                 onClick={onClosePopup}>
                                Сохранить
                            </div>
                        </div>
                    </div>
                </>
            ) : (<></>)}
        </div>

    );
}
export default TasksTagDisplay;
