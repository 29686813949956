import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Store from "./store/store";
import { createContext } from "react";
import "./globalstyles/colors.css"
import "./globalstyles/size.pc.css"
import "./globalstyles/scrollbar.pc.css"
import '@fontsource-variable/montserrat';
import './globalstyles/input.pc.css'
import { BrowserRouter } from 'react-router-dom';

interface State {
    store: Store;
}

const store = new Store();

export const Context = createContext<State>({
    store,
})

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Context.Provider value={{ store }}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Context.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
