import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import "./TaskPerson.css"
import {useParams} from "react-router-dom";
import {IFilesDisplay, IFileTaskFilters, IGetFilesTaskDisplayData} from "../../../models/FileModels";
import {Context} from "../../../index";
import {IGetAllTaskPerson} from "../../../models/IAllTaskPerson";
import StandartPerson from "../../../assets/avatar.svg"


const TaskPerson: FC = () => {
    const { id } = useParams();
    const { store } = useContext(Context);
    const [allPerson, setAllPerson] = useState<IGetAllTaskPerson | undefined>(undefined);
    const [showFullList, setShowFullList] = useState(false);
    const listRef = useRef(null);
    const handleMouseEnter = () => {
        setShowFullList(true);
    };

    const handleMouseLeave = () => {
        setShowFullList(false);
    };

    const getAllTaskPerson = async () => {
        const res = await store.getAllTaskPerson(Number(id));
        setAllPerson(res)
    };

    useEffect(() => {
        (async () => {
            await getAllTaskPerson()
        })();
    }, [id]);

    return (
        <div className="task__person--block">
            <div className="task__person--block__executor">
                <div className="task__person--block--name">
                    Исполнитель
                </div>
                <div className="task__person--block--person">
                    <div className="task__person--block--img">
                        {allPerson?.executor?.photo?.length ? <img src={allPerson?.executor.photo}/> : <img src={StandartPerson}/> }
                    </div>
                    <div className="task__person--block--name__person">
                        {allPerson?.executor.surname + " " + allPerson?.executor.name?.slice(0,1)}
                    </div>
                </div>
            </div>
            <div className="task__person--block__subtasks">
                <div className="task__person--block--name">
                    Подзадачи - {allPerson?.subTask ? 1 : 0}
                </div>
                <div className="task__person--block__subtasks--name">
                    {allPerson?.subTask ? allPerson?.subTask.length > 25 ? allPerson?.subTask.slice(0,25)+"..." : allPerson?.subTask : "Подзадач нет"}
                </div>
            </div>
            <div className="task__person--block__group">
                <div className="task__person--block--name">
                    Группа
                </div>
                <div>
                    <div className="task__person--block--line__group" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={listRef}>
                        {allPerson?.group.slice(0, 5).map((item, index) => (
                            <div key={"person-" + index} className="task__person--block--name__person--group">
                                <div className="task__person--block--img">
                                    {item.photo ? <img src={item.photo}/> : <img src={StandartPerson} />}
                                </div>
                                <div className="task__person--block--name__person--in">
                                    {item.surname?.slice(0, 1) + "" + item.name?.slice(0, 1)}
                                </div>
                            </div>
                        ))}

                        {showFullList &&
                        allPerson?.group.slice(5).map((item, index) => (
                            <div key={"person-" + index} className="task__person--block--name__person--group">
                                <div className="task__person--block--img">
                                    {item.photo ? <img src={item.photo}/> : <img src={StandartPerson} />}
                                </div>
                                <div className="task__person--block--name__person--in">
                                    {item.surname?.slice(0, 1) + "" + item.name?.slice(0, 1)}
                                </div>
                            </div>
                        ))}

                        {allPerson?.group?.length! > 5 && !showFullList && (
                            <div className="task__person--block--more">
                                +{allPerson?.group?.length! - 5}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="task__person--block__autor">
                <div className="task__person--block--name">
                    Автор
                </div>
                <div className="task__person--block--person">
                    <div className="task__person--block--img">
                        {allPerson?.autor.photo ? <img src={allPerson?.autor.photo}/> : <img src={StandartPerson}/>}
                    </div>
                    <div className="task__person--block--name__person">
                        {allPerson?.autor.surname + " " + allPerson?.autor.name?.slice(0, 1)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TaskPerson;