
import React, { FC, createContext, useContext } from 'react';
import './InfoPanel.css'
import { observer } from 'mobx-react-lite';

interface InfoPanel {
    count: number,
    idKey: string
}
const InfoPanel: FC<InfoPanel> = ({ count, idKey }) => {

    return (
        <div className='infoPanelWrapp' key={idKey}>
            {count}
        </div>
    );
}
export default observer(InfoPanel);

