import React, {createContext, FC, useEffect, useRef} from 'react';
import InputChat from "../../Shared/InputChat/InputChat";
import "./TaskChat.css"

export const ChatContext = createContext<any>(null);


const TaskChat: FC = () => {
    return (
        <div>
            <InputChat />
        </div>
    );
};

export default TaskChat;