import { FC, useContext, useEffect, useState } from "react";
import { Context } from "../../index";
import "../../globalstyles/page.pc.css";
import { observer } from "mobx-react-lite";
import "../../pages/Project/Project.css";
import BtnAdd from "../../component/buttons/btnAdd.component/BtnAdd";
import EditProject from "../../component/Shared/AddEditProject/AddEditProject";

const Projects: FC = () => {
    const { store } = useContext(Context);
    const [openEdit, setOpenEdit] = useState<boolean>(false);

    useEffect(() => {}, []);

    return (
        <div>
            <div style={{marginLeft: "10px"}}>
                <BtnAdd
                    onClickFunc={() => {
                        setOpenEdit(true);
                    }}
                    type=""
                />
            </div>
            {openEdit && <EditProject setOpen={setOpenEdit} />}
        </div>
    );
};

export default observer(Projects);
