import $api from "../http";
import {AxiosResponse} from "axios";
import {ProjectResponse, ProjectsResponse} from "../models/response/ProjectResponse";
import {
    IGetProjectHistoryDisplay, IProjectChangesDisplay,
    IProjectGeneralInfo,
    IProjectProgressInfo,
    IProjectEditReq,
    IProjectDeleteReq, IProjectCreateReq
} from "../models/ProjectModels";
import {IGetTeamDisplay, ITeamDisplay} from "../models/TeamModels";

export default class ProjectService {

    static async addProject(
        groupId: number, photoId: number | undefined, startDate: Date, allottedTime: number,
        description?: string, shortDescription?: string, parentProjectId?: number):
        Promise<AxiosResponse<ProjectResponse>> {
        return $api.post<ProjectResponse>("/addProject", {groupId, photoId, startDate, allottedTime, description, shortDescription, parentProjectId})
    }

    static async createProject(body: IProjectCreateReq): Promise<AxiosResponse<IProjectCreateReq>> {
        return $api.post<IProjectCreateReq>(`/addProject`, body); //TODO fix endpoint
    }

    static async getAllProject(): Promise<AxiosResponse<ProjectsResponse>> {
        return $api.post<ProjectsResponse>('/getAllProject');
    }

    static async getCurentProject(id: number): Promise<AxiosResponse<ProjectsResponse>> {
        return $api.post<ProjectsResponse>('/getCurentProject', {id});
    }

    static async getProjectHistoryDisplayData(body: IGetProjectHistoryDisplay): Promise<AxiosResponse<IProjectChangesDisplay>> {
        return $api.post<IProjectChangesDisplay>(`Project/getProjectHistory`, body); //TODO fix endpoint
    }

    static async getProjectTeamDisplayData(body: IGetTeamDisplay): Promise<AxiosResponse<ITeamDisplay>> {
        return $api.post<ITeamDisplay>(`Project/getTeamMembers`, body);
    }

    static async getProjectProgressData(projectId: number): Promise<AxiosResponse<IProjectProgressInfo>> {
        return $api.get<IProjectProgressInfo>(`Project/getStatisticData?projectId=${projectId}`);
    }

    static async getProjectGeneralInfo(projectId: number): Promise<AxiosResponse<IProjectGeneralInfo>> {
        return $api.get<IProjectGeneralInfo>(`Project/projectGeneralInfo?projectId=${projectId}`);
    }

    static async editProject(body: IProjectEditReq): Promise<AxiosResponse<IProjectEditReq>> {
        return $api.post<IProjectEditReq>(`/Project/editProject`, body); //TODO fix endpoint
    }

    static async deleteProject(body: IProjectDeleteReq): Promise<AxiosResponse<IProjectEditReq>> {
        return $api.post<IProjectDeleteReq>(`/Project/deleteProject`, body); //TODO fix endpoint
    }

}