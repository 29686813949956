import React, { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { BrowserRouter, Link, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import Authorization from "./pages/Authorization/Authorization";
import Login from "./pages/Login/Login";
import MainPage from "./pages/Main/MainPage";
import Project from "./pages/Project/Project";
import Board from "./pages/Board/Board";
import Test from "./pages/Test";
import Projects from './pages/Projects/Projects';
import Header from './component/Header/Header';
import MenuForm from './component/LeftMenu/MenuForm';
import { Context } from '.';
import UserMonitor from "./pages/UserMonitor/UserMonitor";
import Chats from "./pages/Chats/Chats";
import Reference from "./pages/Reference/Reference";
import Arhive from "./pages/Arhive/Arhive";
import { url } from 'inspector';
import { toJS } from 'mobx';
import Task from "./pages/Task/Task";
import './component/styles/input.css'
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import RecoverPassword from './pages/RecoverPassword/RecoverPassword';
import Feedback from './pages/Feedback/Feedback';
import ToastNotification from './component/Shared/ToastNotification/ToastNotification';
import SystemSettings from "./pages/SystemSettings/SystemSettings";

interface ParentNode {
    label: string,
    key: string,
}

interface TreeNode {
    key?: string | undefined;
    label?: string | undefined;
    children?: TreeNode[] | undefined;
    parent?: ParentNode[]
}

interface IBreadCrumb {
    label: string,
    urll: string
}

function getUrl(currKey: string): string {
    let path = '/'
    const key: string[] = currKey.split('-');
    const url = key?.length === 1 ? key[0] : key[1];
    let id = key?.length === 1 ? undefined : key[0];
    if (url === 'petProject') {
        path = id ? `/project/${id}` : `/${url}`;
    } else {
        path = id ? `/${url}/${id}` : `/${url}`;

    }

    return path;

}

// Тип уведомления
export type Toast = {
    id: number;
    text: string;
    content: ReactNode;
};

export const AppContext = createContext<any>(null);

const App = () => {
    const MAX_ZOOM = 1;
    const MIN_ZOOM = 0.5;

    const [breadCrumb, setBreadCrumb] = useState<IBreadCrumb[]>([]);
    const [infoPanelCount, setInfoPanelCount] = useState(0);
    const [projectsCount, setprojectsCount] = useState(0);
    const { store } = useContext(Context);
    const [nodes, setNodes] = useState<TreeNode[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<any>();
    const navigate = useNavigate();
    const [metaKey, setMetaKey] = useState(true);
    const [expandedKeys, setExpandedKeys] = useState<any>();
    const [isMainPage, setIsMainPage] = useState<boolean>(false);
    const [currentZoom, setCurrentZoom] = useState<number>(1);
    const [toasts, setToasts] = useState<Toast[]>([]); //Toast уведомления
    const currentHref = window.location.pathname;
    

    useEffect(() => {
        (async () => {
            await getData();
        })();
    }, []);
    

    useEffect(() => {
        findNode()
    }, [nodes]);

    useEffect(() => {
        handleSelectedKeys();
        handleBreadCrumb();
        setIsMainPage(
            currentHref !== "/"
            && currentHref !== "/login"
            && currentHref !== "/forgotPassword"
            && currentHref !== "/feedback"
            && !currentHref.includes("/recoverPassword"));
    }, [currentHref]);

    useEffect(() => {
        handleSelectedKeys();
        //Этот кусок кода заполняет переменные из стора currentBoardData и currentProjectId используя информацию из breadCrumd
        if (breadCrumb && breadCrumb[breadCrumb.length - 1]?.urll?.includes('board')) {
            store.setCurrentBoardData(breadCrumb[breadCrumb.length - 1]);
            const currentProjectUrl = breadCrumb[breadCrumb.length - 2].urll;
            const currentProjectId: number = Number(currentProjectUrl.split('/')[2]);
            store.setCurrentProjectId(currentProjectId)
        }

        if (breadCrumb && breadCrumb[breadCrumb.length - 1]?.urll?.includes('project')) {
            const currentProjectUrl = breadCrumb[breadCrumb.length - 1].urll;
            const currentProjectId: number = Number(currentProjectUrl.split('/')[2]);
            store.setCurrentProjectId(currentProjectId)
        }
    }, [breadCrumb]);


    //На основе currentHref составляет путь хлебных крошек
    const findNode = () => {
        let key = currentHref.slice(1).split('/').reverse().join('-');

        nodes.forEach((i: TreeNode) => {
            if (i.key === key) {
                setBreadCrumbData(i);
            } else {
                if (i.children && i.key === 'projects') {
                    i.children.forEach((project: TreeNode) => {
                        if (project.key === key) {
                            setBreadCrumbData(project);
                        } else {
                            if (project.children) {
                                return project.children.forEach((petProject: TreeNode) => {
                                    if (petProject.key === key) {
                                        setBreadCrumbData(petProject);
                                    } else {
                                        if (petProject.children) {
                                            return petProject.children.forEach((board: TreeNode) => {
                                                if (board.key === key) {
                                                    setBreadCrumbData(board);
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                        }
                    })
                }
            }
        });


    }
    const handleBreadCrumb = () => {
        if (breadCrumb?.length !== 1 && !(breadCrumb?.length === 0)) {
            let index: any = breadCrumb.findIndex((i) => i.urll === currentHref);
            if (index !== -1 || index === 0) {
                breadCrumb.splice(index + 1);
                setBreadCrumb([...breadCrumb]);
            } else {
                findNode();
            }
        } else {
            findNode();
        }
    }


    //На основе пути хлебных крошек раскрывает нужные пункты меню
    const handleSelectedKeys = () => {
        let a = breadCrumb.map((i: any) => i.urll);
        let c: any = {};
        a.forEach(i => {
            let r = '';
            if (i[0] === '/') {
                r = i.slice(1).split('/').reverse().join('-');
            } else {
                r = i.split('/').reverse().join('-');
            }

            c[r] = true;
        });
        setSelectedKeys((prev: any) => prev = c)
        setExpandedKeys((prev: any) => prev = c)
    }

    const getData = async () => {
        await store.getProjectsForLeftMenu();
        setData();
    }

    //Формирует пункты левого меню, вызывает handleSelectedKeys
    const setData = () => {
        let a: any = [
            // {
            //     key: 'main',
            //     label: 'Главная',
            //     icon: 'iconLeftMenueMainLogo',
            //     children: [],
            // },
            {
                key: 'projects',
                label: 'Проекты',

                icon: 'iconLeftMenueProgectsLogo',
                children: store.tree.nodes
            },
            {
                key: 'monitor',
                label: 'Монитор пользователя',
                icon: 'iconLeftMenueMonitorLogo',
                children: []
            },
            // {
            //     key: 'task',
            //     label: 'Задача',
            //     icon: 'iconLeftMenueMainLogo',
            //     children: [],
            // },
            // {
            //     key: 'chats',
            //     label: 'Чаты',
            //     data: 'Movies Folder',
            //     icon: 'iconLeftMenueChatsLogo',
            //     children: []
            // },
            // {
            //     key: 'reference',
            //     label: 'Справка',
            //     data: 'Movies Folder',
            //     icon: 'iconLeftMenueSpravkaLogo',
            //     children: []
            // },
            {
                key: 'arhive',
                label: 'Архив',
                data: 'Movies Folder',
                icon: 'iconLeftMenueArhiveLogo',
                children: []
            },
            {
                key: 'trash',
                label: 'Корзина',
                data: 'Movies Folder',
                icon: 'iconLeftMenueTrashLogo',
                children: []
            },
        ]

        setNodes([...a]);

        store.tree?.nodes && setInfoPanelCount(store.tree.nodes.length);
        handleSelectedKeys();
    }

    const onSelect = (event: any) => {
        let url = getUrl(event.node.key);
        setBreadCrumbData(event.node);
        navigate(url);
    };

    //Добавляет элемент в путь хлебных крошек
    const setBreadCrumbData = (node: TreeNode) => {
        const key: string[] = node.key!.split('-');
        let id = key?.length === 1 ? undefined : key[0];
        let path: any = [];
        if (!id) {
            let item = {
                label: node.label,
                urll: key[0],
                template: () => <Link to={key[0]}><a>{node.label}</a></Link>
            }
            path.push(item);

        } else {
            node.parent?.forEach((i: any) => {
                let url = getUrl(i.key!);
                let item = {
                    label: i.label,
                    urll: url,
                    template: () => <Link to={url}><a>{i.label}</a></Link>
                }
                path.push(item);
            })
            let url = getUrl(node.key!);
            let item = {
                label: node.label,
                urll: url,
                template: () => <Link to={url}><a>{node.label}</a></Link>
            }
            path.push(item);
        }
        setBreadCrumb([...path]);
    }

    // Функция для отображения Toast уведомлений
    const showToast = (text: string, content?: ReactNode) => {
        const TIME_VISIBLE = 4000;
        const id = Date.now();
        setToasts((prevToasts) => [...prevToasts, { id, content, text }]);
    
        setTimeout(() => {
          setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
        }, TIME_VISIBLE);
    };

    const removeToast = (toastId: number): void => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== toastId));
    }

    return (
        <div className="main__page">
            <AppContext.Provider
                value={{
                    breadCrumb, setBreadCrumb,
                    projectsCount, setprojectsCount,
                    infoPanelCount, setinfoPanelCount: setInfoPanelCount,
                    setSelectedKeys,
                    setExpandedKeys,
                    onSelect,
                    metaKey,
                    nodes,
                    expandedKeys,
                    selectedKeys,
                    getData,
                    handleBreadCrumb,
                    currentZoom,
                    setCurrentZoom,
                    MAX_ZOOM,
                    MIN_ZOOM,
                    showToast
                }}>

                {isMainPage && <MenuForm />}

                <div className={`main__page-body ${isMainPage ? "" : "external__page-body"}`}>
                    {isMainPage && <Header />}
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/forgotPassword" element={<ForgotPassword />} />
                        <Route path="/recoverPassword/:token" element={<RecoverPassword />} />
                        <Route path="/feedback" element={<Feedback />}/>
                        {/* <Route path="/main" element={<MainPage />} /> */}
                        {/* <Route path="/test" element={<Test />} /> */}
                        <Route path="/chats" element={<Chats />} />
                        <Route path="/arhive" element={<Arhive />} />
                        <Route path="/monitor" element={<UserMonitor />} />
                        <Route path="/reference" element={<Reference />} />
                        <Route path="/projects" element={<Projects />} />
                        <Route path="/task/:id" element={<Task />} />
                        <Route path="/board/:boardId/task/:id" element={<Task />} />
                        <Route path="/project/:id" element={<Project />} />
                        <Route path="/board/:boardId" element={<Board />} />
                        <Route path="*" element={<Authorization />} />
                        <Route path="/system/settings" element={<SystemSettings />} />
                    </Routes>
                    <ToastNotification toasts={toasts} removeToast={removeToast} />
                </div>

            </AppContext.Provider>
        </div >

    );
}
export default App;
