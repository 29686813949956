import React, { FC, useContext, useEffect } from 'react';
import { Context } from "../../index";
import MenuForm from "../../component/LeftMenu/MenuForm";
import Header from "../../component/Header/Header";
import "../../globalstyles/page.pc.css"
import { useNavigate } from "react-router-dom";
import '../../pages/Main/MainPage.css'
import Project from '../Project/Project';

const UserMonitor: FC = (index) => {
    const { store } = useContext(Context);
    const navigate = useNavigate();

    const [state, setState] = React.useState({
        activeTab: "project"
    })

    useEffect(() => {
        if (!store.isAuth) {
            navigate("/login");
        }
    }, [store.isAuth, navigate]);

    if (store.isAuth) {
        return (
            <div>В разработке...</div>
        );
    } else {
        return (
            <div className="main__page">
                <h1>Авторизуйтесь</h1>
            </div>
        );
    }
}

export default UserMonitor;