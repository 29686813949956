import React, {createContext, FC, useContext, useEffect, useState} from 'react';
import TaskFiles from "../TaskFiles/TaskFiles";
import {
    IFileFilterOptions,
    IFileFilters,
    IFilesDisplay,
    IFileTaskFilters,
    IGetFilesDisplayData,
    IGetFilesTaskDisplayData
} from "../../../models/FileModels";
import {Context} from "../../../index";
import {useParams} from "react-router-dom";
import TaskPerson from "../TaskPerson/TaskPerson";

export const TaskRightInfoContext = createContext<any>(null);
const TaskRightInfo: FC = (index) => {
    const { id } = useParams();
    const { store } = useContext(Context);

    return (
        <div className="task--right__info--menu">
            <div>
                <TaskFiles/>
            </div>
            <div>
                <TaskPerson/>
            </div>

            {/*  Исполнитель  */}
            {/*  Подзадачи  */}
            {/*  Группа  */}
            {/*  Автор  */}
        </div>
    );
};

export default TaskRightInfo;