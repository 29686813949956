import React, {FC, useContext, useEffect, useState} from 'react';
import "./TaskInformationForm.css"
import EditIcon from '../../../assets/edit.svg'
import { useParams } from 'react-router-dom';
import { Context } from "../../../index";
import { ITaskGeneralInfo } from '../../../models/ITask';
import TaskPriority from "../../Shared/TaskPriority";
import TaskType from "../../Shared/TaskType";
import AddButton from '../../buttons/btnAdd.component/BtnAdd'
import TaskTeg from '../../Shared/TaskTeg';
import AddTeg from '../../../assets/addTeg.svg'
import {ITag, ITaskFormDisplay} from "../../../models/ITaskFormDisplay";
import TaskCreateEditForm from "../../Board/TaskCreateEditForm/TaskCreateEditForm";
import {ITasksDisplayFilters} from "../../../models/TaskModels";
import { TaskContext } from '../../../pages/Task/Task';
import TaskAddEditForm from "../TaskAddEditForm/TaskAddEditForm";
import TagSelector from "../../Shared/TagSelectionPopup/TagSelectionPopup";
import TasksTagDisplay from "../../Shared/TagSelectorAndDisplay/TasksTagDisplay";


interface ITaskPage {
    projectId?: number;
}


const TaskInformationForm: FC<ITaskPage> = ({projectId}) => {
    const {taskInformation, loadTaskInfo} = useContext(TaskContext);
    const { id } = useParams();
    const [open, setOpen] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [showAddTeg, setshowAddTeg] = useState(false);
    const [tagName, setTagName] = useState('');
    const [hideSelf, setHideSelf] = useState<boolean>(false);
    const [taskEditData, setTaskEditData] = useState<ITaskFormDisplay | undefined>(undefined);
    const [showEditForm, setShowEditForm] = useState<boolean>(false);
    const { store } = useContext(Context);
    const [tags, setTags] = useState<ITag[]>([]);
    const [taskFilters, setTaskFilters] = useState<ITasksDisplayFilters>({
        priority: [],
        type: [],
        status: [],
        tag: []
    });

    function onEditClick(e: any) {
        setOpen(false)
        if (e.target.innerText === 'Добавить канбан') {
            // setActiveIndex(2);
            // setOpenInputBoard(!openInputBoard);
        } else {
            // setActiveIndex(1);
            // setOpenInputProject(!openInputProject);
        }
    }


    function updateTaskPriority(index: number) {
        (async () => {
            try {
                let res = await store.updateTaskPriority(Number(id), index);
                await loadTaskInfo();
            } catch (error) {
                console.log(error)
            }
        })();
    }

    function updateTaskType(index: number) {
        (async () => {
            try {
                let res = await store.updateTaskType(Number(id), index);
                await loadTaskInfo();
            } catch (error) {
                console.log(error)
            }
        })();
    }


    const taskPriorityDisplay = (rowData: any, index: number) => {
        const style = JSON.parse(rowData?.color);
        return (
            <button className="button__taskPriorityDisplay--chek" onClick={() => updateTaskPriority(index)}>
                <TaskPriority name={rowData.name} color={style?.color} icon={style.icon} />
            </button>
        );
    };

    const taskTypeDisplay = (rowData: any, index: number) => {
        const style = JSON.parse(rowData?.color);
        return (
            <button className="button__taskPriorityDisplay--chek" onClick={() => updateTaskType(index)}>
                <TaskType name={rowData.name} color={style?.color} icon={style?.icon} />;
            </button>
        );
    };

    function onAddTags(_tags: ITag[]) {
        let newTags = tags?.filter((xx) => xx.id < 0);
        setTags([...newTags, ..._tags].filter((value, index, self) => self.indexOf(value) === index));
        (async () => {
            await store.addTagsInTasks(tags, taskInformation?.id!)
        })();
    }

    useEffect(() => {

    }, [tags]);

    function removeTag(tag: ITag) {
        setTags([...tags?.filter((xx) => xx.id !== tag.id)]);
    }

    const taskTagsDisplay = (rowData: any) => {
        // const style = JSON.parse(rowData?.color);
        return <TaskTeg name={rowData.name} color={rowData?.color} />;
    };

    function onAddClick(){
        console.log(tags)
        showAddTeg ? setshowAddTeg(false) : setshowAddTeg(true);
    }

    function createTag() {
        if (tagName.length < 3) {
            console.log("Добавить сюда pop-up (Длина меньше 3)")
        } else {
            (async () => {
                await store.addTeg(Number(id), store.currentProjectId, tagName, "#fff");
                setshowAddTeg(false);
                setTagName("");
                await loadTaskInfo();
            })();
        }
    }

    function onEditTaskClick(e: any) {
        (async () => {
            const taskFilters = await store.getProjectTasksFilters(
                store.currentProjectId
            );
            if (
                taskFilters &&
                taskFilters.type &&
                taskFilters.priority &&
                taskFilters.status
            )
            setTaskFilters(taskFilters);
            e.stopPropagation();
            setHideSelf(true);
            store.getTaskForm(Number(id)).then((res) => {
                if (!res?.id) {
                    return;
                }
                setTaskEditData(res);
                setShowEditForm(!showEditForm);
            });
            await loadTaskInfo()
        })();
    }

    function onOverlayClick() {
        onEditFormClose();
        (async () => {
            await loadTaskInfo();
        })();
    }

    function onEditFormClose() {
        setShowEditForm(false);
        setHideSelf(false);
        (async () => {
            await loadTaskInfo();
        })();
    }

    function onAddTagsAccept(_tags: any[]){
        // addTagsFunc(_tags)
        // _tags.length == 0 ? tags = [] : tags.filter(xx => _tags.includes(xx.id))
    }

    function onFormClick(event: any) {
        event.stopPropagation();
        (async () => {
            await loadTaskInfo();
        })();
    }

    useEffect(() => {
        (async () => {
            try {
                await loadTaskInfo();
                console.log(taskInformation?.tags!)
                setTags(taskInformation?.tags!);
            } catch (error) {
            }
        })();
    }, [id]);

    useEffect(() => {
        (async () => {
            try {
                await loadTaskInfo();
                store.setCurrentProjectId(projectId ?? 1);
            } catch (error) {
            }
        })();
        // store.setTaskUpdate(false)
    }, [store.isTaskUpdate]);



    return (
        <div className="widget__task--information">
            {showEditForm ? (
                <div className="full-screen-overlay" onClick={onOverlayClick}>
                    <div className="popup-form-background" onClick={onFormClick}>
                        <TaskAddEditForm mode={"edit"} taskId={Number(id)} closeFunc={onEditFormClose}/>
                    </div>
                </div>
            ) : (
                <></>
            )}
            <div className="widget__task--information__head">
                <div className="widget__task--information__name">
                    <p className="widget__task--information__p--style">{taskInformation?.shortDescription}</p>
                </div>
                <div className="widget__task--information__edit" onClick={onEditTaskClick}>
                    <div className="widget__task--information__edit--block" >
                        <img src={EditIcon}/>
                    </div>
                </div>
            </div>
            <div className="widget__task--information__desk--block">
                <p className="m-0">{taskInformation?.description}</p>
            </div>
            <div className="widget__task--information__priority--type">
                <div className="widget__task--information__priority--type--block">
                    <div className="widget__task--information__priority--type--block__head">
                        <p className="widget__task--information__p--style">Приоритет</p>
                    </div>
                    <div className="widget__task--information__priority--type--block__list">
                        {taskInformation?.priority?.map((priority, index) => (
                            <div key={index+1}
                                 style={priority.curent ? {} : {opacity: 0.3}}
                                 className="widget__task--information__priority--type--block__list--margin">
                                {taskPriorityDisplay(priority, index+1)}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="widget__task--information__priority--type--block">
                    <div className="widget__task--information__priority--type--block__head">
                        <p className="widget__task--information__p--style">Тип задачи</p>
                    </div>
                    <div className="widget__task--information__priority--type--block__list">
                        {taskInformation?.type?.map((type, index) => (
                            <div key={index+1}
                                 style={type.curent ? {} : {opacity: 0.3}}
                                 className="widget__task--information__priority--type--block__list--margin">
                                {taskTypeDisplay(type, index+1)}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="widget__task--information__tegs">
                <div>
                    <p className="widget__task--information__p--style">Теги</p>
                </div>
                <div  className="widget__task--information__tegs--view">
                    <div className="widget__task--information__tegs--btn">
                        <AddButton onClickFunc={onAddClick} type={'tag'}/>
                    </div>
                    {
                        showAddTeg && (
                            // <TagSelector tags={taskInformation?.tags!}
                            //     projectId={taskInformation?.projectId!}
                            //     taskId={taskInformation?.id!}
                            //     addTagsFunc={onAddTagsAccept}
                            //     closeFunc={onAddClick}/>

                            // <TasksTagDisplay projectId={taskInformation?.projectId!} taskId={taskInformation?.id!} tags={tags}
                            //                  addTagsFunc={onAddTags} removeTag={removeTag}/>


                            <div className="widget__task--information__tegs--btn__add--teg">
                                <div className="widget__task--information__tegs--btn__add--teg--input">
                                    <input
                                        type="text"
                                        placeholder="Введите название"
                                        value={tagName}
                                        onChange={(e) => setTagName(e.target.value)}
                                    />
                                </div>
                                <div className="widget__task--information__tegs--btn__add--teg--btn">
                                    <button onClick={createTag}
                                            className="widget__task--information__tegs--btn__add--teg--btn__img">
                                        <img src={AddTeg} alt="Add tag"/>
                                    </button>
                                </div>
                            </div>
                        )
                    }
                    <div className="widget__task--information__tegs--view__teg">
                        {
                            taskInformation?.tags && (
                                <>
                                    {taskInformation?.tags.length > 5 ? (
                                        <>
                                            {taskInformation?.tags.slice(0, 5).map((tag, index) => (
                                                <div key={index} className="widget__task--information__priority--type--block__list--margin">
                                                    {taskTagsDisplay(tag)}
                                                </div>
                                            ))}
                                            <div
                                                className="widget__task--information__tegs--view__teg--tooltip"
                                                onMouseEnter={() => setShowTooltip(true)}
                                                onMouseLeave={() => setShowTooltip(false)}
                                            >
                                                <p>{`+${taskInformation?.tags.length - 5}`}</p>
                                                {showTooltip && (
                                                    <div className="widget__task--information__tegs--view__teg--tooltip__open">
                                                        {taskInformation?.tags.slice(5).map((tag, index) => (
                                                            <div key={index} className="widget__task--information__tegs--view__teg--tooltip__open--teg">
                                                                {taskTagsDisplay(tag)}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        taskInformation?.tags.map((tag, index) => (
                                            <div key={index} className="widget__task--information__priority--type--block__list--margin">
                                                {taskTagsDisplay(tag)}
                                            </div>
                                        ))
                                    )}
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TaskInformationForm;