import React, { FC, useContext, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import moveArrow from "../../../assets/MoveArrowBlue.svg"

export interface IMoveButtonProps {
    onClickFunc: () => void;
}

const BtnMove: FC<IMoveButtonProps> = ({onClickFunc}) => {
    return (
        <div className="button-base" onClick={onClickFunc}>
            <img src={moveArrow} />
        </div>
    )
}

export default observer(BtnMove);