import React, {FC, useContext, useEffect, useState} from "react";
import "./BoardFastFilters.css";
import { BoardContext } from "../../../pages/Board/Board";
import { IFilterData } from "../../../models/TaskModels";
import PriorityButton from "../../../assets/priorityButton.svg"
import ArrowDown from "../../../assets/arrowDown.svg"
import TypeButton from "../../../assets/typeButton.svg"
import TagFilter from "../../../assets/tagFilter.svg"
import Search from "../../Shared/Search/Search";

const BoardFastFilters: FC = () => {
    const [showPriorities, setShowPriorities] = useState<boolean>(false);
    const [showTypes, setShowTypes] = useState<boolean>(false);
    const [showTags, setShowTags] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("");
    const [tagsForView, setTagsForView] = useState<IFilterData[]>([]);
    const { taskFilters, changeFastFilters, selectedFastFilters } = useContext(BoardContext);

    useEffect(() => {
        const searchTextLowercase = searchText.toLowerCase();
        setTagsForView(taskFilters.tag.filter(t => t.name.toLowerCase().includes(searchTextLowercase)));
    }, [searchText, taskFilters]);

    const handlePriorityBtnClick = () => {
        setShowPriorities(!showPriorities);
        setShowTypes(false);
        setShowTags(false);
    };

    const handleTypeBtnClick = () => {
        setShowTypes(!showTypes);
        setShowPriorities(false);
        setShowTags(false);
    };

    const handleTagBtnClick = () => {
        setShowTags(!showTags);
        setShowTypes(false);
        setShowPriorities(false);
    };


    const handleSelectFilter = (filter: IFilterData, filterType: 'priority' | 'type' | 'tag') => {
        if (selectedFastFilters[filterType]?.id === filter.id) {
            changeFastFilters({
                priority: filterType === 'priority' ? undefined : selectedFastFilters.priority,
                type: filterType === 'type' ? undefined : selectedFastFilters.type,
                tag: filterType === 'tag' ? undefined : selectedFastFilters.tag,
            });
        } else if (filter.id === 0) {
            changeFastFilters({
                priority: filterType === 'priority' ? undefined : selectedFastFilters.priority,
                type: filterType === 'type' ? undefined : selectedFastFilters.type,
                tag: filterType === 'tag' ? undefined : selectedFastFilters.tag,
            });
        } else {
            changeFastFilters({
                priority: filterType === 'priority' ? filter : selectedFastFilters.priority,
                type: filterType === 'type' ? filter : selectedFastFilters.type,
                tag: filterType === 'tag' ? filter : selectedFastFilters.tag,
            });
        }
    };

    const handleSelectType = (type: IFilterData) => handleSelectFilter(type, 'type');
    const handleSelectPriority = (priority: IFilterData) => handleSelectFilter(priority, 'priority');
    const handleSelectTag = (tag: IFilterData) => handleSelectFilter(tag, 'tag');

    // useEffect(() => {
    //     setSaveTags(taskFilters.tag ?? []);
    // }, [taskFilters]);

    return (
        <div className="board-fast-filters-container">
            <div className="board-filter-container">
                <button className="board-filter-container__priority" onClick={handlePriorityBtnClick}>
                    <img src={PriorityButton}/>
                    {selectedFastFilters.priority
                        ? selectedFastFilters.priority.name
                        : "Приоритет"}
                    <img src={ArrowDown}/>
                </button>
                {showPriorities ? (
                    <div>
                        <div
                            className="fast-filters-popup-overlay"
                            onClick={() => setShowPriorities(false)}
                        ></div>
                        <div
                            className="board-filter-dropdown"
                            onClick={(e) => e.stopPropagation()}
                        >
                            {taskFilters.priority.map((priority) => (
                                <div
                                    key={priority.id}
                                    onClick={() =>
                                        handleSelectPriority(priority)
                                    }
                                    className={`${
                                        selectedFastFilters.priority?.id ===
                                        priority.id
                                            ? "selected board-filter-dropdown__item"
                                            : "board-filter-dropdown__item"
                                    }`}
                                >
                                    {priority.name}
                                </div>
                            ))}
                            <div onClick={() =>
                                handleSelectPriority({id: 0, name: "Все", style: ""})
                            }
                                className={`${
                                    selectedFastFilters.priority?.id === 0
                                        ? "board-filter-dropdown__item--del"
                                        : "board-filter-dropdown__item--del"
                                }`}
                            >
                                сбросить
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="board-filter-container">
                <button className="board-filter-container__type" onClick={handleTypeBtnClick}>
                    <img src={TypeButton}/>
                    {selectedFastFilters.type
                        ? selectedFastFilters.type.name
                        : "Тип"}
                    <img src={ArrowDown}/>
                </button>
                {showTypes ? (
                    <div>
                        <div
                            className="fast-filters-popup-overlay"
                            onClick={() => setShowTypes(false)}
                        ></div>
                        <div className="board-filter-dropdown">
                            {taskFilters.type.map((type) => (
                                <div
                                    key={type.id}
                                    onClick={() => handleSelectType(type)}
                                    className={`${
                                        selectedFastFilters.type?.id === type.id
                                            ? "selected board-filter-dropdown__item"
                                            : "board-filter-dropdown__item"
                                    }`}
                                >
                                    {type.name}
                                </div>
                            ))}
                            <div onClick={() =>
                                handleSelectType({id: 0, name: "Все", style: ""})
                            }
                                className={`${
                                    selectedFastFilters.type?.id === 0
                                        ? "board-filter-dropdown__item--del"
                                        : "board-filter-dropdown__item--del"
                                }`}
                            >
                                сбросить
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="board-filter-container">
                <button className="board-filter-container__type" onClick={handleTagBtnClick}>
                    <img src={TagFilter}/>
                    {selectedFastFilters.tag
                        ? selectedFastFilters.tag.name
                        : "Теги"}
                    <img src={ArrowDown}/>
                </button>
                {showTags ? (
                    <div>
                        <div className="fast-filters-popup-overlay" onClick={() => setShowTags(false)}></div>
                        <div className="board-filter-dropdown__tag">
                            <div className="board-filter-dropdown__tag--search">
                                <Search onSubmitFunc={() => {}} defaultValue={searchText} changeSearchValue={(text) => {setSearchText(text)}}/>
                            </div>
                            <div className="board-filter-dropdown__tag--search__block--teg">
                                {tagsForView.map((tagCur) => (
                                    <div key={tagCur.id} onClick={() => handleSelectTag(tagCur)}
                                        className={`${selectedFastFilters.tag?.id === tagCur.id
                                                ? "selected__board-filter-dropdown__item board-filter-dropdown__item"
                                                : "board-filter-dropdown__item"
                                        }`}
                                        style={tagCur.style ? {backgroundColor: tagCur.style} : {}}
                                    >
                                        {tagCur.name}
                                    </div>
                                ))}
                            </div>
                            <div onClick={() => {
                                handleSelectTag({id: 0, name: "Все", style: ""})
                                setSearchText("");
                            }
                            }
                                 className={`${
                                     selectedFastFilters.tag?.id === 0
                                         ? "selected__board-filter-dropdown__item board-filter-dropdown__item--del"
                                         : "board-filter-dropdown__item--del"
                                 }`}
                            >
                                сбросить
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default BoardFastFilters;
