import { FC, useContext, useEffect } from "react";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import FeedbackForm from "../../component/FeedBack/FeedbackForm";

const Feedback: FC = () => {
    const { store } = useContext(Context);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            store.chekAuth();
        }

    }, [store.isAuth, store.isActivate]);

    return (
        <div>
            <FeedbackForm userLoggedIn={store.isAuth} />
        </div>
    );
};

export default observer(Feedback);
