import axios from 'axios';
import {AuthResponse} from "../models/response/AuthResponse";


let SYSTEM_URL = ''
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    SYSTEM_URL = 'http://localhost:3000'
}


export const API_URL = `${SYSTEM_URL ?? ''}/api`

const $api= axios.create({
    withCredentials: true,
    baseURL: API_URL
});

$api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    return config;
})

$api.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    const originalRequest = error.config;
    if(error.response.status == 401 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
            const response = await axios.get<AuthResponse>(`${API_URL}/refresh`, {withCredentials: true});
            localStorage.setItem('token', response.data.accessToken);
            return $api.request(originalRequest);
        } catch (err) {
            console.log("The user is not logged in!")
        }
    }
    throw error;
})

export default $api;