import React, { createContext, FC, ReactEventHandler, useContext, useEffect, useRef, useState } from 'react';
import { Context } from "../../index";
import "../../globalstyles/page.pc.css"
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import '../../pages/Project/Project.css'
import '../../component/styles/input.css'
import NameProject from "../../component/Project/NameProject/NameProject";
import Files from "../../component/Project/Files/Files";
import HistoryProject from "../../component/Project/HistoryProject/HistoryProject";
import Team, { ITeamFilter } from "../../component/Project/Team/Team";
import GeneralInformation from "../../component/Project/GeneralInformation/GeneralInformation";
import Tasks, { ITaskFilter, PAGE_SIZE } from "../../component/Project/Tasks/Tasks";
import { IGetTasksDisplayData, ITaskDisplay, ITasksDisplay, ITasksDisplayFilters } from "../../models/TaskModels";
import {
    IGetProjectHistoryDisplay,
    IProjectChangesDisplay,
    IProjectGeneralInfo,
    IProjectProgressInfo
} from "../../models/ProjectModels";
import { IFileFilterOptions, IFileFilters, IFilesDisplay, IGetFilesDisplayData } from "../../models/FileModels";
import { IGetTeamDisplay, ITeamDisplay, ITeamMemberDisplay } from "../../models/TeamModels";
import { IIParametrsFilter } from "../../models/IFilterModel";
import { IFilter } from "../../models/response/IFilterResponse";
import { toJS } from 'mobx';
import EditProject from '../../component/Shared/AddEditProject/AddEditProject';
import { initials } from '../../helpers/Inicials';
import { AppContext } from '../../App';



export const ProjectContext = createContext<any>(null);

interface ISort {
    date?: "asc" | "desc";
    status?: "asc" | "desc";
    responsible?: "asc" | "desc";
    type?: "asc" | "desc";
    priority?: "asc" | "desc";
    nameTask?: "asc" | "desc";
}

interface ItemTeam {
    name: string;
    code: string;
}

interface ISort {
    date?: "asc" | "desc";
    status?: "asc" | "desc";
    responsible?: "asc" | "desc";
    type?: "asc" | "desc";
    priority?: "asc" | "desc";
    nameTask?: "asc" | "desc";
}

interface ItemTeam {
    name: string;
    code: string;
}
const Project: FC = (index) => {

    const { store } = useContext(Context);

    const { id } = useParams();
    const { currentZoom, setCurrentZoom, MIN_ZOOM, MAX_ZOOM } = useContext(AppContext);
    //NAME PROJECT
    const [nameData, setNameData] = useState<IProjectGeneralInfo | undefined>(undefined);
    //PROGRESS INFO
    const [progressData, setProgressData] = useState<IProjectProgressInfo | undefined>(undefined);
    //FILES
    const [files, setFiles] = useState<IFilesDisplay | undefined>(undefined);
    const [filesFilterOptions, setFilesFilterOptions] = useState<IFileFilterOptions | undefined>(undefined);
    //HISTORY
    const [changes, setChanges] = useState<IProjectChangesDisplay | undefined>(undefined);
    const [scrollEndedChanges, setScrollEndedChanges] = useState(false);
    //TEAM
    const [team, setTeam] = useState<ITeamMemberDisplay[]>([]);
    const [scrollEndedTeam, setScrollEndedTeam] = useState(false);
    const [persons, setPersons] = useState<ItemTeam[]>([]);
    const [roles, setRoles] = useState<ItemTeam[]>([]);
    const [pageTeam, setPageTeam] = useState(1);


    //TASKS
    const [scrollEndedTasks, setScrollEndedTasks] = useState(false);
    const [tasks, setTasks] = useState<ITaskDisplay[]>([]);
    const [taskFilters, setTaskFilters] = useState<ITasksDisplayFilters>({
        priority: [],
        type: [],
        status: [],
        tag: []
    });

    const [sortAsc, setSortAsc] = useState(false);
    const [page, setPage] = useState(1);
    const [openTask, setOpenTask] = useState(false);

    //FILTERS
    const [filterDataTask, setFilterDataTask] = useState<IFilter[] | undefined>();
    const [filterDataTeam, setFilterDataTeam] = useState<IFilter[] | undefined>();

    const [TaskReset, setTaskReset] = useState<any | undefined>();
    const [TeamReset, setTeamReset] = useState<any | undefined>();

    const [selectedUsersTasks, setSelectedUsersTasks] = useState(null);
    const [datesTasks, setDatesTasks] = useState(null);
    const [selectedUsersTeams, setSelectedUsersTeams] = useState(null);

    const [counterTasks, setCounterTasks] = useState<number>(0);
    const [counterTeam, setCounterTeam] = useState<number>(0);

    const [checkedFiltersTasks, setCheckedFiltersTasks] = useState<any>({
        projectId: Number(id),
        status: undefined,
        priority: undefined,
        type: undefined,
        name: undefined,
        responsible: undefined,
        date: undefined
    });

    const [checkedFiltersTeams, setCheckedFiltersTeams] = useState<any>({
        projectId: Number(id),
        position: undefined,
        responsible: undefined,
    });
    const fieldFilterTasks: IIParametrsFilter = {
        projectId: Number(id),
        date: true,
        responsible: true,
        status: true,
        typeTask: true,
        priority: true,
        role: false,
        fileType: false,
    };
    const fieldFilterTeams: IIParametrsFilter = {
        projectId: Number(id),
        date: false,
        responsible: false,
        status: false,
        typeTask: false,
        priority: false,
        role: true,
        fileType: false,
    }
    const fieldFilterFiles: IIParametrsFilter = {
        projectId: Number(id),
        date: false,
        responsible: false,
        status: false,
        typeTask: false,
        priority: false,
        role: false,
        fileType: true,
    }
    

    useEffect(() => {
        const filterTasks = structuredClone(checkedFiltersTasks);
        // const filterTasks = JSON.parse(JSON.stringify(checkedFiltersTasks));
        filterTasks.projectId = Number(id);
        setCheckedFiltersTasks(filterTasks);

        const filterTeams = structuredClone(checkedFiltersTeams);
        // const filterTeams = JSON.parse(JSON.stringify(checkedFiltersTeams));
        filterTeams.projectId = Number(id);
        setCheckedFiltersTasks(filterTeams);

        setPage(1);
        setScrollEndedTasks(false);

        setPageTeam(1);
        setScrollEndedTeam(false);
        (async () => {
            try {
                await loadNameProjectData()
                await loadProjectProgressData()
                await loadFiles({
                    projectId: Number(id),
                    name: '',
                    fileType: []
                })
                await loadTeamMembers(0, PAGE_SIZE, {
                    projectId: Number(id),
                    name: undefined,
                    role: undefined
                })
                await loadChanges(0, PAGE_SIZE)
                await loadTasks(0, PAGE_SIZE, {
                    projectId: Number(id),
                    responsible: undefined,
                    date: undefined,
                    taskId: undefined,
                    status: undefined,
                    priority: undefined,
                    typeTask: undefined,
                    name: undefined
                }, { date: sortAsc ? "asc" : "desc" });
                await loadSystemPersonWithRoles();
                await getFilterData(fieldFilterTasks, 'task');
                await getFilterData(fieldFilterTeams, 'team');

                const taskFilters = await store.getProjectTasksFilters(
                    store.currentProjectId
                );
                if (
                    taskFilters &&
                    taskFilters.type &&
                    taskFilters.priority &&
                    taskFilters.status
                )
                    setTaskFilters(taskFilters);
            } catch (error) {
                console.log(error)
            }
        })();

        store.setCurrentProjectId(Number(id))
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [id, store.currentProjectId]);

    const loadSystemPersonWithRoles = async () => {
        await store.getSystemPersonWithRoles();
        if (store.personsWithRoles && store.personsWithRoles.person && store.personsWithRoles.roles) {
            let personsData: ItemTeam[] = [];
            let rolesdata: ItemTeam[] = [];
            store.personsWithRoles.person.forEach(i => {
                let item = {
                    name: initials(i.name),
                    code: i.id.toString()
                }
                personsData.push(item)
            });

            store.personsWithRoles.roles.forEach(i => {
                let item = {
                    name: i.name,
                    code: i.id.toString()
                }
                rolesdata.push(item)
            });

            setPersons([...personsData]);
            setRoles([...rolesdata]);

        }
    }
    //NAME PROJECT FUNCTIONS
    const loadNameProjectData = async () => {
        let res = await store.getProjectGeneralInfo(Number(id))
        setNameData(res!);
    }
    //PROJECT PROGRESS INFO
    const loadProjectProgressData = async () => {
        let res = await store.getProjectProgressData(Number(id))
        setProgressData(res!);
    }
    //FILES FUNCTIONS
    const loadFiles = async (filters: IFileFilters) => {

        let req: IGetFilesDisplayData = {
            filters: filters
        }

        const res = await store.getFilesDisplayData(req) as IFilesDisplay;
        setFiles(res);
    }
    const loadFileFilterOptions = async () => {
        const resFilterOptions = await store.getFileFilterOptions() as IFileFilterOptions;
        setFilesFilterOptions(resFilterOptions)
    }
    //HISTORY FUNCTIONS
    const loadChanges = async (skip: number, take: number) => {
        let req: IGetProjectHistoryDisplay = {
            skip: skip,
            take: take,
            filters: {
                projectId: store.currentProjectId
            }
        }

        const res = await store.getProjectHistoryDisplayData(req) as IProjectChangesDisplay;
        setChanges(res);
    }

    //TODO add appendTasks
    //TEAM FUNCTIONS
    const loadTeamMembers = async (skip: number, take: number, filters: ITeamFilter) => {
        console.log(skip, take, filters)
        const newPageReq: IGetTeamDisplay = {
            skip: skip,
            take: take,
            filters: filters
        };

        const res = await store.getProjectTeamDisplayData(newPageReq);
        if (res !== null) {
            setTeam(structuredClone(res?.team!));
            // setTeam([...JSON.parse(JSON.stringify(res?.team!))]);
            console.log(team)
        } else {
            setTeam([]);
            // setTeam([...JSON.parse(JSON.stringify([]))]);
        }
    }

    const appendTeamMembers = async (skip: number, take: number, filters: ITeamFilter) => {
        const newPageReq: IGetTeamDisplay = {
            skip: skip,
            take: take,
            filters: filters
        };

        const res = await store.getProjectTeamDisplayData(newPageReq);
        if (res?.team && res?.team?.length > 0) {
            setTeam([...team, ...res.team]);
        } else { //TODO check res status
            setScrollEndedTeam(true)
        }
    }

    const handleLoadTeamMembers = async (skip: number, take: number, filters: ITeamFilter) => {
        await appendTeamMembers(skip, take, filters);
    }
    const handleUpdateFiltersTeam = async (filters: ITeamFilter) => {
        setScrollEndedTeam(false)
        await loadTeamMembers(0, PAGE_SIZE, filters);
    }

    //TASK FUNCTIONS
    const loadTasks = async (skip: number, take: number, filters: ITaskFilter, sort: ISort) => {
        
        setScrollEndedTasks(false);
        const newPageReq: IGetTasksDisplayData = {
            skip: skip,
            take: take,
            filters: filters,
            sort: sort
        };

        const res = await store.getProjectTasksDisplayData(newPageReq) as ITasksDisplay;
        if (res !== null) {
            setTasks(structuredClone(res.tasks));
            // setTasks(JSON.parse(JSON.stringify(res.tasks)));
        } else {
            setTasks([]);
            // setTasks(JSON.parse(JSON.stringify([])));
        }

    }
    const appendTasks = async (skip: number, take: number, filters: ITaskFilter, sort: ISort) => {
        const newPageReq: IGetTasksDisplayData = {
            skip: skip,
            take: take,
            filters: filters,
            sort: sort
        };

        const res = await store.getProjectTasksDisplayData(newPageReq) as ITasksDisplay;
        if (res?.tasks && res?.tasks?.length > 0) {
            setTasks([...tasks, ...res.tasks]);
        } else { //TODO check res status
            setScrollEndedTasks(true);
        }

    }
    const handleLoadPageTasks = async (skip: number, take: number, filters: ITaskFilter, sort:ISort) => {
        await appendTasks(skip, take, filters, sort);
    }
    const handleUpdateFiltersTasks = async (filters: ITaskFilter, sort: ISort) => {
        setScrollEndedTasks(false);
        await loadTasks(0, PAGE_SIZE, filters, sort);
    }

    const getFilterData = async (body: IIParametrsFilter, typeFilter: 'team' | 'task') => {
        let data = await store.getDataForFilter(body);
        //Преобразования Имя пользователя к формату ФИО
        let a: any = data?.map(i => {
            if (i.type && i.type === 'users') {
                let c: any = i.items?.map((x: any) => { return { code: x.code, name: initials(x.name!) } });
                if (c?.length) {
                    i.items = c;
                    return i;
                }
            } else {
                return i;
            }
        })
        // -------------------------------------------------

        let resetData = structuredClone(a);
        if (data?.length && a?.length && typeFilter === 'task') {
            setFilterDataTask(a);
            console.log('filterrrr',data)
            setTaskReset(resetData)
        } else if (data && typeFilter === 'team') {
            setFilterDataTeam(data);
            setTeamReset(resetData);
        }
    }

    const applayFiltersTask = async () => {
        setPage(1);
        setScrollEndedTasks(false);
        await loadTasks(0, PAGE_SIZE, checkedFiltersTasks, {});
    }

    const applayFiltersTeams = async () => {
        setPageTeam(1);
        setScrollEndedTeam(false);
        await loadTeamMembers(0, PAGE_SIZE, checkedFiltersTeams);
    }

    const resetFilter = (typeFilter: 'team' | 'task') => {
        if (typeFilter === 'task') {
            TaskReset && setFilterDataTask(structuredClone(TaskReset));
            let nullFilters = {
                projectId: Number(id),
                status: undefined,
                priority: undefined,
                typeTask: undefined,
                name: undefined,
                responsible: undefined,
                date: undefined,
                taskId: undefined
            }
            setCheckedFiltersTasks({ ...nullFilters })
            setSelectedUsersTasks(null);
            setDatesTasks(null);
            setCounterTasks(0);

            (async () => {
                setPage(1);
                setScrollEndedTasks(false);
                await loadTasks(0, PAGE_SIZE, nullFilters, {});
            })();

        } else if (typeFilter === 'team') {
            setPageTeam(1);
            TeamReset && setFilterDataTeam(structuredClone(TeamReset));
            
            let nullFilters = {
                projectId: Number(id),
                role: undefined,
                name: '',
            }
            setCheckedFiltersTeams({...nullFilters});
            setSelectedUsersTeams(null);
            setCounterTeam(0);
            (async () => {
                setScrollEndedTeam(false);
                await loadTeamMembers(0, PAGE_SIZE, nullFilters);
            })();
        }
    }

    const handleWheelEvent = (e: any) => {
        if (e.ctrlKey) {
            e.preventDefault();
            e.stopPropagation();
            setCurrentZoom((z: any) => {
                const newZoom = e.deltaY < 0 ? z + 0.05 : z - 0.05;
                return Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, newZoom));

            });
        }
    };

    // useEffect(() => {
    //     //Делается через document потому что необходима конструкция passive: false
    //     //доступная только так. Отвязка ивента нужна, иначе не работает, особенности react
    //     //
    //     const element: any = document.querySelector(".zoomable");
    //     if (element) {
    //         element.addEventListener("wheel", handleWheelEvent, {
    //             passive: false,
    //         });
    //     }
    //     return () => element.removeEventListener("wheel", handleWheelEvent);
    // }, [currentZoom]);

    return (
        <div className='widgets_main_container'>
            <ProjectContext.Provider value={{
                loadFiles, persons,
                roles, filterDataTask, applayFiltersTask,
                checkedFiltersTasks, setCheckedFiltersTasks,
                filterDataTeam, checkedFiltersTeams, setCheckedFiltersTeams, applayFiltersTeams,
                resetFilter,
                setFilterDataTask,
                setFilterDataTeam,
                selectedUsersTasks, setSelectedUsersTasks,
                datesTasks, setDatesTasks,
                selectedUsersTeams, setSelectedUsersTeams,
                counterTasks, setCounterTasks,
                counterTeam, setCounterTeam,
                loadNameProjectData,
                sortAsc, setSortAsc,
                page, setPage,
                pageTeam, setPageTeam,
                taskFilters,
                loadTasks,
                setScrollEndedTeam
            }}>
                <div className='widgets_container_1'>
                    <div className='widgets_container_column'>

                        <NameProject data={nameData} loadNameProjectData={loadNameProjectData} />
                        <Files files={files} filterOptions={filesFilterOptions} updateFilters={loadFiles} />
                        <HistoryProject change={changes!} loadPage={loadChanges} scrollEnded={scrollEndedChanges} />
                    </div>
                    <div className='widgets_container_column'>
                        <Team members={team} scrollEnded={scrollEndedTeam} loadPage={handleLoadTeamMembers}
                            updateFilters={handleUpdateFiltersTeam} loadTeamMembers={loadTeamMembers} />
                        <GeneralInformation data={progressData} />

                        <Tasks tasks={tasks} scrollEnded={scrollEndedTasks} loadPage={handleLoadPageTasks} updateFilters={handleUpdateFiltersTasks} />
                    </div>
                </div>
                <div className='widgets_container_2'>
                    <NameProject data={nameData} loadNameProjectData={loadNameProjectData} />
                    <Team members={team} scrollEnded={scrollEndedTeam} loadPage={handleLoadTeamMembers} updateFilters={handleUpdateFiltersTeam}
                        loadTeamMembers={loadTeamMembers} />
                    <Files files={files} filterOptions={filesFilterOptions} updateFilters={loadFiles} />
                    <GeneralInformation data={progressData} />
                    <HistoryProject change={changes!} loadPage={loadChanges} scrollEnded={scrollEndedChanges} />
                    <Tasks tasks={tasks} scrollEnded={scrollEndedTasks} loadPage={handleLoadPageTasks} updateFilters={handleUpdateFiltersTasks} />
                </div>
            </ProjectContext.Provider>
        </div>

    );
}

export default observer(Project);
