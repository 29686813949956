import { FC } from 'react';
import { observer } from "mobx-react-lite";
import cancelIcon from "../../../assets/cancel.svg"


export interface ICancelButtonProps {
    onClickFunc: () => void;
}

const BtnCancel: FC<ICancelButtonProps> = ({ onClickFunc }) => {
    function searchKeyPress(e: any) {
        // look for window.event in case event isn't passed in
        e = e || window.event;
        if (e.keyCode == 13) {

            document.getElementById('btnSearch')?.click();
            return false;
        }
        return true;
    }
    return (

        <button  className="button-base btn_margin-left" onClick={() => onClickFunc()}>
            <img src={cancelIcon} />
        </button>

        

    )
}

export default observer(BtnCancel);