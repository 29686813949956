import $api from "../http";
import { IColumnEdit } from "../models/IColumnEdit";
import { IMakeColumnProxy } from "../models/IMakeColumnProxy";
import { IMakeColumnCommon } from "../models/IMakeColumnCommon";
import { IDeleteColumn } from "../models/IDeleteColumn";
import { IAddColumn } from "../models/IAddColumn";
import { IMoveColumn } from "../models/IMoveColumn";

export default class ColumnService {

    static async addColumn(body: IAddColumn): Promise<void> {
        return $api.post("/Project/dashBoard/addColumn", body);
    }

    static async editColumn(body: IColumnEdit): Promise<void> {
        return $api.post("/Project/dashBoard/editColumn", body);
    }

    static async makeColumnProxy(body: IMakeColumnProxy): Promise<void> {
        return $api.post("/Project/dashBoard/makeColumnProxy", body);
    }

    static async makeColumnCommon(body: IMakeColumnCommon): Promise<void> {
        return $api.post("/Project/dashBoard/makeColumnCommon", body);
    }

    static async deleteColumn(body: IDeleteColumn): Promise<void> {
        return $api.post("/Project/dashBoard/deleteColumn", body);
    }

    static async moveColumn(body: IMoveColumn): Promise<void> {
        return $api.post("/Project/column/moveColumn", body);
    }
}
