import React, { FC, useContext, useEffect } from 'react';
import { Context } from "../../../index";
import { observer } from "mobx-react-lite";
import "../../styles/avatar.pc.css";
import UserIcon from "../../../assets/avatar.svg"
const Avatar: FC = () => {
    const { store } = useContext(Context);
    return (
        <div >
            <img className='avatar'src={UserIcon} alt={store.user.name + " " + store.user.surname + " " + store.user.middlename} />
        </div>
    )
}

export default observer(Avatar);