import React, { FC, useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import More from "../../../assets/moreGray.svg";
import "../../styles/dashboard.pc.css";
import "../../styles/popUp.pc.css";
import useOutsideAlerter from '../../../customHooks/useOutsideAlert';
import { BoardContext } from '../../../pages/Board/Board';
import { IColumnDisplay } from '../../../models/IColumnDisplay';

export interface IMoreButtonProps {
    onClickFunc: (e: any) => void;
    data: string[],
    typeForKey: string,
    open: boolean,
    setOpen: (open: boolean) => void,
    columnsChoises?: IColumnDisplay[],
    taskId?: number
}



const BtnMore: FC<IMoreButtonProps> = ({ onClickFunc, data, typeForKey, open, setOpen, columnsChoises, taskId }) => {
    const [showColumns, setShowColumns] = useState<boolean>(false);
    const outsideAlerterRef: any = useOutsideAlerter(() => setOpen(false));
    const { handleMoveTask, columns } =
        useContext(BoardContext);

    return (
        <div key={typeForKey} className="button-base" ref={outsideAlerterRef} onMouseLeave={() => setShowColumns(false)}>
            <div onClick={() => setOpen(true)}>
                <img src={More} />
            </div>
            {open &&
                <div >
                    <div className='popUpWrapper'>
                        <div className='popUpContainer' >
                            {data.map(i => (
                                (i === "Переместить") ?
                                    (<div key={i} className='popUpItem'>
                                        <div className='p_12_popUP' key={i} onMouseEnter={() => setShowColumns(true)}>{i}</div>
                                    </div>) :
                                    (<div key={i} className='popUpItem'>
                                        <div className='p_12_popUP' onMouseLeave={() => setShowColumns(false)} key={i} onClick={(e: any) => onClickFunc(e)}>{i}</div>
                                    </div>)
                            ))}


                        </div>
                        {showColumns && taskId && columnsChoises && columnsChoises?.length > 0 ? (
                            <div className="popUpWrapper_inner ">
                                <div className="popUpContainer">
                                    {columnsChoises.map((c) => (
                                        <div
                                            className="popUpItem_inner p_12_popUP"
                                            onClick={() =>
                                                handleMoveTask(taskId, c.id, 0)
                                            }
                                        >
                                            
                                            {c.name.length > 10 ? c.name?.slice(0, 10) + "..." : c.name}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            }
        </div>
    )
}

export default observer(BtnMore);