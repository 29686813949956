import { FC, useContext, useState } from "react";
import { IColumnDisplay } from "../../../models/IColumnDisplay";
import "./BoardMenuColumn.css";
import { BoardContext } from "../../../pages/Board/Board";
import DragDrop from "../../../assets/dragDrop.svg"
import DeleteColumn from "../../../assets/deleteElementGray.svg"
import EditColumn from "../../../assets/edit.svg"
import ExitCross from "../../../assets/cancelGreey.svg"

interface IBoardMenuColumnProps {
    column: IColumnDisplay;
    onColumnDragEnd: () => void;
    draggable: boolean;
    deletable: boolean;
}

const BoardMenuColumn: FC<IBoardMenuColumnProps> = ({
    column,
    onColumnDragEnd,
    draggable,
    deletable,
}) => {
    const [renameMode, setRenameMode] = useState<boolean>(false);

    const { handleDeleteColumn, handleEditColumn } = useContext(BoardContext);

    const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        setTimeout(() => {
            const columnElement = (e.target as HTMLElement).closest('.board-menu-column');
            if (columnElement) {
                columnElement.classList.add('drag-hidden');
            }
        }, 0);
        e.dataTransfer.setData(
            "application/json",
            JSON.stringify({
                columnId: column.id,
            })
        );
    };

    const onDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
        setTimeout(() => {
            const columnElement = (e.target as HTMLElement).closest('.board-menu-column');
            if (columnElement) {
                columnElement.classList.remove('drag-hidden');
            }
        }, 100);
        onColumnDragEnd();
    };

    const handleNameChange = (e: any) => {
        if (e.key === "Enter") {
            handleEditColumn(column.id, e.target.value);
            setRenameMode(false);
        }
    };

    return (
        <div
            id={`column-${draggable ? column.id : ""}`}
            className='board-menu-column'
        >
            <div
                className="drag-button"
                draggable={draggable}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
            >
                {draggable ? 
                    <div className="dragDrop__edit">
                        <img src={DragDrop}/>
                    </div> 
                : 
                    <div className="dragDrop__edit"></div>
                }
            </div>
            <div className="board-menu-column__block">
                {renameMode ? (
                    <div className="board-menu-column__block--name">
                        <input
                            type="text"
                            defaultValue={column.name}
                            onKeyDown={handleNameChange}
                        ></input>
                    </div>
                ) : (
                    <div className="board-menu-column__block--name">
                        {column.name}
                    </div>
                )}
                <div className="board-menu-column__block__button">
                    <button onClick={() => setRenameMode(!renameMode)}>
                        {renameMode ? 
                            <img src={ExitCross}/>
                        : 
                            <img src={EditColumn}/>
                        }
                    </button>
                    {deletable ? (
                        <button onClick={() => handleDeleteColumn(column.id)}>
                            <img src={DeleteColumn}/>
                        </button>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default BoardMenuColumn;
